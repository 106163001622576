import React from 'react';
import {
    FormControl,
    Heading,
    FormLabel,
    HStack,
    Button,
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Icon,
    Text,
    Input,
} from '@chakra-ui/react';

export function DownloadUserInfo() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <div>
            <Box padding={4} mt={12}>
                <FormControl>
                    <Heading
                        size='sm'
                        mb={2}
                        fontWeight='600'
                        onClick={onOpen}
                        _hover={{ cursor: 'pointer' }}
                    >
                        Last ned din informasjon <Icon mr={2} />
                    </Heading>

                    <Text>Du kan laste ned det som er lagret på deg.</Text>
                    <Text>Skriv inn passord for å bekrefte</Text>

                    <FormLabel mt={4}>Passord</FormLabel>
                    <Input
                        borderColor='NorskTur.Navy'
                        type='password'
                        maxW={{ base: '100%', md: '30%' }}
                    />

                    <HStack spacing={6} display='block' bottom={8} mt='2rem'>
                        <Button variant='primary'>Last ned HTML </Button>
                        <Button variant='primary'>Last ned JSON</Button>
                    </HStack>
                </FormControl>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg='NorskTur.Sand'>
                    <ModalHeader>Din informasjon</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Du kan når som helst laste ned en kopi av informasjonen
                        din. <br />
                        <br />
                        Du kan velge å få informasjonen i HTML-format som er
                        enkelt å lese, eller JSON-format som er enklere å
                        importere til en annen tjeneste. <br />
                        <br />
                        Nedlasting av informasjonen er en passordbeskyttet
                        prosess som bare du har tilgang til.
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='primary' mr={3} onClick={onClose}>
                            Lukk
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}
