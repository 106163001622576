import React from 'react';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { OutFlightIcon } from 'common/assets/outFlightIcon';
import { useSearchParams } from 'react-router-dom';
import { InFlightIcon } from 'common/assets/inFlightIcon';
import { LuggageIcon } from 'common/assets/luggageIcon';
import { useAuthContext } from '../context/authContext';
import { ReturnLink } from '../nav/returnLink';
import { StandardSpinner } from '../spinner/standarsSpinner';
import FlightLeg from './flightLeg.view';
import { useFlight, useTrip } from '../../api/hooks/ApiHooks';

export default function FlightInfoContainer() {
    const auth = useAuthContext();
    const [searchParams] = useSearchParams();

    const { data: crmTrip } = useTrip(auth, searchParams.get('tripNumber'));

    const {
        data: flightData,
        isFetching: isFlightInfoFetching,
        isIdle: isFlightInfoIdle,
    } = useFlight(auth, crmTrip?.tripId);

    function formatLegHeading(
        firstLeg: string,
        firstLegCode: string,
        lastleg: string,
        lastLegCode: string
    ) {
        return `${firstLeg} (${firstLegCode}) - ${lastleg} (${lastLegCode})`;
    }

    const isLoading = isFlightInfoFetching || isFlightInfoIdle;
    return (
        <Container maxW='container.xl'>
            {isLoading ? (
                <StandardSpinner title='Henter flyinformasjon' />
            ) : (
                flightData && (
                    <div>
                        <ReturnLink />
                        <Container maxW='container.md'>
                            <Heading size='lg' mt={2}>
                                Flyinformasjon
                            </Heading>
                            {flightData.outboundFlightLegs &&
                                flightData.outboundFlightLegs.length > 0 && (
                                    <div>
                                        <Heading size='md' mt={8} mb={4}>
                                            Utreise
                                        </Heading>
                                        {flightData.outboundFlightPnr && (
                                            <Heading size='sm' mb={6}>
                                                Bookingreferanse:{' '}
                                                {flightData.outboundFlightPnr}
                                            </Heading>
                                        )}
                                        <Heading size='sm'>
                                            <OutFlightIcon mr={2} boxSize={6} />
                                            {formatLegHeading(
                                                flightData.originAirportCity ||
                                                    '',
                                                flightData.originAirportCode ||
                                                    '',
                                                flightData.destinationAirportCity ||
                                                    '',
                                                flightData.destinationAirportCode ||
                                                    ''
                                            )}
                                        </Heading>

                                        <FlightLeg
                                            flightLegs={
                                                flightData.outboundFlightLegs
                                            }
                                        />
                                    </div>
                                )}

                            {flightData.inboundFlightLegs &&
                                flightData.inboundFlightLegs.length > 0 && (
                                    <div>
                                        <Heading size='md' mt={8} mb={4}>
                                            Hjemreise
                                        </Heading>
                                        {flightData.inboundFlightPnr && (
                                            <Heading size='sm' mb={6}>
                                                Bookingreferanse:{' '}
                                                {flightData.inboundFlightPnr}
                                            </Heading>
                                        )}
                                        <Heading size='sm'>
                                            <InFlightIcon mr={2} boxSize={6} />
                                            {formatLegHeading(
                                                flightData.destinationAirportCity ||
                                                    '',
                                                flightData.destinationAirportCode ||
                                                    '',
                                                flightData.originAirportCity ||
                                                    '',
                                                flightData.originAirportCode ||
                                                    ''
                                            )}
                                        </Heading>
                                        <FlightLeg
                                            flightLegs={
                                                flightData.inboundFlightLegs
                                            }
                                        />
                                    </div>
                                )}

                            <Heading size='sm' mt={8}>
                                <LuggageIcon mr={2} boxSize={6} />
                                Tillatt bagasjevekt
                            </Heading>
                            <Box ml={8}>
                                <Text>
                                    Innsjekket bagasje:{' '}
                                    {flightData.checkedBaggageCount} kolli à{' '}
                                    {flightData.checkedBaggageWeight} kg
                                </Text>
                                <Text>
                                    Håndbagasje:{' '}
                                    {flightData.carryonBaggageCount} kolli à{' '}
                                    {flightData.carryonBaggageWeight} kg
                                </Text>
                            </Box>

                            {/* <Button variant={'primary'}>
                            <DownloadIcon mr={2} /> Last ned flydokument
                        </Button> */}
                        </Container>
                    </div>
                )
            )}
        </Container>
    );
}
