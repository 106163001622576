import { IBookedTrip } from '../interfaces/trip';

export function sortTripByDepartureDate(
    tripA: IBookedTrip,
    tripB: IBookedTrip
) {
    const a = tripA.departureDate || 0;
    const b = tripB.departureDate || 0;

    if (!a || !b) return 0;

    if (a < b) return -1;

    if (a > b) return 1;

    return 0;
}
