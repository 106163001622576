import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    List,
    ListItem,
} from '@chakra-ui/react';
import { TravellerReport } from 'common/interfaces/reports';
import moment from 'moment';
import React from 'react';

type Proptypes = {
    listData?: TravellerReport[];
};

function TravellerReportMobile(props: Proptypes) {
    const { listData } = props;

    const data = React.useMemo(() => {
        if (!listData) return [];

        const contacts = listData.map((c) => c?.contact).flat();

        const sortedContacts = contacts.sort((a, b) => {
            const lastNameA = a && a.lastName ? a.lastName.toUpperCase() : '';
            const lastNameB = b && b.lastName ? b.lastName.toUpperCase() : '';
            if (lastNameA < lastNameB) {
                return -1;
            }
            if (lastNameA > lastNameB) {
                return 1;
            }
            return 0;
        });
        return sortedContacts.map((contact) => ({
            name: `${contact?.lastName || ''}, ${contact?.firstName || ''} ${
                contact?.travellerType === 'TourLeader' ? '(RL)' : ''
            }`,
            email: contact?.emailAddress || '',
            mobilePhone: contact?.mobilePhone || '',
            remark: contact?.remarksEng || '',
            birthdate: moment(contact?.birthDate || '').format('DD-MM-YYYY'),
        }));
    }, [listData]);

    return (
        <Accordion defaultIndex={[0]} allowMultiple>
            {data.map((row, index) => (
                <AccordionItem key={`${row.name}${index}`}>
                    <AccordionButton
                        _expanded={{
                            bg: 'NorskTur.Navy',
                            color: 'white',
                        }}
                    >
                        <Box as='span' flex='1' textAlign='left'>
                            {row.name}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel bg='NorskTur.GråLys'>
                        <List>
                            <ListItem>
                                <strong>Epost:</strong> {row.email}
                            </ListItem>
                            <ListItem>
                                <strong>Mobil:</strong> {row.mobilePhone}
                            </ListItem>
                            <ListItem>
                                <strong>Merknad:</strong> {row.remark}
                            </ListItem>
                            <ListItem>
                                <strong>Fødelsdag:</strong> {row.birthdate}
                            </ListItem>
                        </List>
                    </AccordionPanel>
                </AccordionItem>
            ))}
        </Accordion>
    );
}

export default TravellerReportMobile;
