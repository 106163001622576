import React from 'react';
import { Icon } from '@chakra-ui/react';

export function OutFlightIcon(props: any) {
    return (
        <Icon viewBox='0 0 24 24' {...props}>
            <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M18.3502 6H13.9809L9.96298 0.251875C9.90941 0.175346 9.83204 0.111747 9.73873 0.0675164C9.64541 0.023286 9.53946 -5.52896e-06 9.43159 9.84476e-10L6.92753 9.84476e-10C6.52115 9.84476e-10 6.22793 0.317812 6.33956 0.637187L8.21397 6H4.28164L2.63011 4.2C2.51466 4.07406 2.33307 4 2.14077 4H0.611959C0.213986 4 -0.0780903 4.30562 0.0186313 4.62125L1.22325 8L0.0186313 11.3787C-0.0780903 11.6944 0.213986 12 0.611959 12H2.14077C2.33345 12 2.51466 11.9259 2.63011 11.8L4.28164 10H8.21397L6.33956 15.3625C6.22793 15.6819 6.52115 16 6.92753 16H9.43159C9.65103 16 9.85365 15.9038 9.9626 15.7481L13.9809 10H18.3502C19.7017 10 22.0203 9.10469 22.0203 8C22.0203 6.89531 19.7017 6 18.3502 6Z'
                    fill='#25384A'
                />
            </svg>
        </Icon>
    );
}
