import React from 'react';
import { User, UserManager } from 'oidc-client';
import { env } from 'env';
import { useAuthContext } from '../context/authContext';
import isAuthenticated from './isAuthenticated';

const manager = new UserManager({
    authority: env.REACT_APP_OIDC_AUTHORITY,
    client_id: env.REACT_APP_OIDC_CLIENT,
    client_secret: env.REACT_APP_OIDC_SECRET,
    redirect_uri: `${window.location.origin}/login/response`,
    post_logout_redirect_uri: `${window.location.origin}/logout/response`,
    response_type: 'code',
    scope: 'norsktur_mypage.fullAccess',
    loadUserInfo: false,
    revokeAccessTokenOnSignout: true,
});

export const useLogin = () => {
    const { user, setUser } = useAuthContext();

    const [authError, setAuthError] = React.useState('');

    React.useEffect(() => {
        manager.getUser().then(async (userFromState: User | null) => {
            if (
                userFromState &&
                isAuthenticated(
                    userFromState.access_token,
                    userFromState.expired
                )
            ) {
                setUser(userFromState);
            } else {
                try {
                    if (window.location.pathname === '/login/response') {
                        const newUser = await manager.signinRedirectCallback();
                        setUser(newUser);
                    } else {
                        await manager.signinRedirect();
                    }
                } catch (error) {
                    setAuthError((error as Error).message);
                    manager.clearStaleState();
                }
            }
        });
        manager.events.addAccessTokenExpiring(() => {
            manager.signinSilent().then(() => {
                manager.getUser().then((silentUser: User | null) => {
                    if (!!silentUser && !silentUser.expired) {
                        setUser(silentUser);
                    }
                });
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        user,
        authError,
    };
};

export const logout = () => {
    return manager.signoutRedirect().catch((error: Error) => {
        throw new Error(error.message);
    });
};

export function completeLogout() {
    return manager
        .signoutRedirectCallback()
        .then(() => {
            manager.removeUser();
        })
        .catch((error: Error) => {
            throw new Error(error.message);
        });
}

export const returnUrl = () => {
    const url = `/connect/authorize/callback?${new URLSearchParams({
        client_id: env.REACT_APP_OIDC_CLIENT,
        redirect_uri: `${window.location.origin}/login/response`,
        response_type: 'code',
        scope: 'norsktur_mypage.fullAccess',
        state: Math.random().toString(32).substring(2, 32),
        code_challenge: Math.random().toString(32).substring(2, 32),
        code_method: 'S256',
        response_mode: 'query',
    })}`;
    return encodeURIComponent(url);
};
