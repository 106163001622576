import React from 'react';

type Operation = 'add' | 'remove' | 'replace' | 'move' | 'copy' | 'test';

export type Patch = {
    op: Operation;
    path: string;
    value?: any;
};

type PatchContextType = {
    patch?: Patch[];
    addPatch: (path: string, value?: any, op?: Operation) => void;
};

const defaultState: PatchContextType = {
    patch: undefined,
    addPatch: () => undefined,
};

export const PatchContext = React.createContext<PatchContextType>(defaultState);

// Might make a hooks in the context to 'filter' out changes if they are the same as the initial state from API.
// in that case, i wont need to export the context, i can let the hook use the context instead!

export const PatchContextProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const [patch, setPatch] = React.useState<Patch[]>();

    const addPatch = (path: string, value?: any, op?: Operation) => {
        setPatch((v) => [
            ...(v || []).filter((current) => current.path !== path),
            { op: op || 'replace', path, value },
        ]);
    };

    const value: PatchContextType = React.useMemo(
        () => ({
            patch,
            addPatch,
        }),
        [patch]
    );

    return (
        <PatchContext.Provider value={value}>{children}</PatchContext.Provider>
    );
};
