import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { env } from 'env';

type InvoiceSummaryProps = {
    invoiceDate: string;
    invoiceDueDate: string;
    cid: string;
};

export default function InvoiceSummary(props: InvoiceSummaryProps) {
    const { invoiceDate, invoiceDueDate, cid } = props;
    const accountNumber = env.REACT_APP_ACCOUNT_NUMBER;
    return (
        <Box
            padding={8}
            mt={8}
            rounded='lg'
            border='1px solid'
            borderColor='NorskTur.GråMedium'
        >
            <Text>Kontonummer: {accountNumber}</Text>
            <Text mt={4}>KID: {cid}</Text>
            <Text mt={4}>Fakturadato: {invoiceDate}</Text>
            <Text mt={4} fontWeight='bold'>
                Forfallsdato: {invoiceDueDate}
            </Text>
        </Box>
    );
}
