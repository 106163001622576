import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Text,
    List,
    ListItem,
} from '@chakra-ui/react';
import { AdditionReport } from 'common/interfaces/reports';
import React from 'react';

type Proptypes = {
    listData?: AdditionReport[];
};

function AdditionReportMobile(props: Proptypes) {
    const { listData } = props;

    const data = React.useMemo(
        () =>
            (listData || []).map(
                ({ contact: contacts, optionalAdditionName }) => {
                    // contact is array type, so we rename here for clarity
                    if (!contacts) return {};

                    if (contacts.length > 1) {
                        return {
                            name: contacts.map((contact) => (
                                <Text mb={2} key={`${contact.firstName}`}>
                                    - {contact.lastName}, {contact.firstName}
                                </Text>
                            )),
                            addition: optionalAdditionName,
                            total: contacts.length,
                        };
                    }
                    return {
                        name: `${contacts[0]?.lastName}, ${contacts[0]?.firstName}`,
                        addition: optionalAdditionName,
                        total: contacts.length,
                    };
                }
            ),
        [listData]
    );

    return (
        <Accordion defaultIndex={[0]} allowMultiple>
            {data.map((row, index) => (
                <AccordionItem key={`${row.addition}${index}`}>
                    <AccordionButton
                        _expanded={{
                            bg: 'NorskTur.Navy',
                            color: 'white',
                        }}
                    >
                        <Box as='span' flex='1' textAlign='left'>
                            {row.addition}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel bg='NorskTur.GråLys'>
                        <List>
                            <ListItem>
                                <strong>Totalt:</strong> {row.total}
                            </ListItem>
                            <ListItem>
                                <strong>Påmeldte:</strong> {row.name}
                            </ListItem>
                        </List>
                    </AccordionPanel>
                </AccordionItem>
            ))}
        </Accordion>
    );
}

export default AdditionReportMobile;
