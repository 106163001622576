import React from 'react';
import {
    FormControl,
    Heading,
    FormLabel,
    HStack,
    Button,
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Icon,
    Text,
    Input,
} from '@chakra-ui/react';

export function DeleteUserInfo() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <div>
            <Box padding={4} mt={12}>
                <FormControl>
                    <Heading
                        size='sm'
                        mb={2}
                        fontWeight='600'
                        onClick={onOpen}
                        _hover={{ cursor: 'pointer' }}
                    >
                        Slett brukerkontoen din <Icon mr={2} />
                    </Heading>

                    <Text>Du sletter nå brukerkontoen din permanent.</Text>
                    <Text>
                        Er du sikker? I så tilfelle må du bekrefte med å taste
                        inn ditt passord
                    </Text>

                    <FormLabel mt={4}>Tast inn passord</FormLabel>

                    <Input
                        borderColor='NorskTur.Navy'
                        type='password'
                        maxW={{ base: '100%', md: '30%' }}
                    />

                    <Text>reCaptcha skal inn under her</Text>

                    <HStack spacing={6} display='block' bottom={8} mt='2rem'>
                        <Button variant='primary'>
                            Slett brukerkontoen min
                        </Button>
                        <Button variant='primary'>Avbryt</Button>
                    </HStack>
                </FormControl>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg='NorskTur.Sand'>
                    <ModalHeader>Slett din brukerkonto</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Hvis du ikke lenger reiser med oss og vil slette
                        brukerkontoen din, kan vi ordne dette for deg. <br />
                        <br />
                        Merk at du ikke kan slette brukerkontoen din før 30
                        dager etter siste hjemkomst. <br />
                        <br />
                        Etter at du har utført sletting, har du 14 dagers
                        mulighet til å angre på dette. <br />
                        <br />
                        Vær oppmerksom på at du ikke vil kunne aktivere
                        brukerkontoen din på nytt eller hente informasjon om
                        turene dine etter av kontoen er slettet. <br />
                        <br />
                        Ved en eventuell ny reise med oss vil du motta ny
                        innloggingsinformasjon. <br />
                        <br />
                        Ønsker du mer informasjon om hvilke data som er lagret
                        om deg, kontakt personvernrådgiveren i Norsk Tur på
                        dataprotection@norsktur.no.
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='primary' mr={3} onClick={onClose}>
                            Lukk
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}
