import React from 'react';
import {
    Box,
    Container,
    Grid,
    GridItem,
    Heading,
    Icon,
    Image,
    List,
    ListItem,
    Text,
} from '@chakra-ui/react';
import { FacebookLogo } from '../assets/facebook';
import { HomeLogo } from '../assets/home';
import { InstagramLogo } from '../assets/instagram';
import LogoWhite from '../assets/Logo-Norsk-Tur-Full-White.svg';
import { MailLogo } from '../assets/mail';
import miljofyrtarnLogo from '../assets/miljofyrtarn.svg';
import { PhoneLogo } from '../assets/phone';
import reisegarantifondetLogo from '../assets/Reisegarantifondet hvit 1.svg';

export default function Footer() {
    return (
        <Box bg='NorskTur.Navy' color='white' mt='auto'>
            <Container maxW='container.xl' py={8}>
                <Grid
                    templateColumns='repeat(auto-fit, minmax(240px, 1fr))'
                    gap={6}
                    pt={8}
                >
                    <Grid
                        templateColumns='repeat(2, 1fr)'
                        templateRows='repeat(2, 1fr)'
                        gap={4}
                    >
                        <GridItem rowSpan={2} colSpan={2}>
                            <Image
                                boxSize='230px'
                                height='auto'
                                src={LogoWhite}
                                alt='Logo'
                            />
                        </GridItem>
                        <GridItem>
                            <Image
                                boxSize='130px'
                                height='auto'
                                src={miljofyrtarnLogo}
                                alt='Logo'
                            />
                        </GridItem>
                        <GridItem>
                            <Image
                                boxSize='130px'
                                height='auto'
                                src={reisegarantifondetLogo}
                                alt='Logo'
                            />
                        </GridItem>
                    </Grid>
                    <Box>
                        <Text lineHeight={1.5} fontSize='18px'>
                            Norsk Tur arrangerer kvalitetsturer over hele
                            verden. Lang erfaring og solide rutiner, samt et
                            rikt nettverk av kontakter sørger for at du som
                            kunde får kvalitet i alle ledd.
                        </Text>
                    </Box>
                    <Box>
                        <List spacing={3}>
                            <ListItem
                                textDecoration='underline'
                                _hover={{ textDecoration: 'none' }}
                            >
                                <a href='tel:+38 12 03 20'>
                                    <Icon
                                        as={PhoneLogo}
                                        boxSize={6}
                                        color='white'
                                        mx={2}
                                    />
                                    Telefon: 38 12 03 20
                                </a>
                            </ListItem>
                            <ListItem
                                textDecoration='underline'
                                _hover={{ textDecoration: 'none' }}
                            >
                                <a href='mailto:reise@norsktur.no'>
                                    <Icon
                                        as={MailLogo}
                                        boxSize={6}
                                        color='white'
                                        mx={2}
                                    />
                                    E-post: reise@norsktur.no
                                </a>
                            </ListItem>
                            <ListItem>
                                <Icon
                                    as={HomeLogo}
                                    boxSize={6}
                                    color='white'
                                    mx={2}
                                    verticalAlign='super'
                                />
                                <Text display='inline-block'>
                                    Norsk Tur AS, Tollbodgata 6, <br />
                                    4611 Kristiansand
                                </Text>
                            </ListItem>
                        </List>
                    </Box>
                    <Box>
                        <Heading size='sm' mb={4}>
                            Følg Norsk Tur:
                        </Heading>
                        <a
                            href='https://www.facebook.com/norsktur'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <Icon
                                as={FacebookLogo}
                                boxSize={10}
                                mr={4}
                                _hover={{
                                    color: 'NorskTur.Navy',
                                    stroke: 'white',
                                }}
                                transition='color .1s ease-in-out'
                            />
                        </a>
                        <a
                            href='https://www.instagram.com/norsktur/'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <Icon
                                as={InstagramLogo}
                                boxSize={10}
                                _hover={{
                                    color: 'NorskTur.Navy',
                                    stroke: 'white',
                                }}
                                transition='color .1s ease-in-out'
                            />
                        </a>
                    </Box>
                </Grid>
            </Container>
        </Box>
    );
}
