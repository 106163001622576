import {
    FormControl,
    Heading,
    FormLabel,
    HStack,
    Button,
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Icon,
    Textarea,
} from '@chakra-ui/react';
import { Patch, PatchContext } from 'common/context/patchContext';
import React from 'react';

type PropTypes = {
    onSubmit: (patch: Patch[]) => void;
    allergies: string;
    remarks: string;
    loading: boolean;
};

export function AllergiesFormCard(props: PropTypes) {
    const { remarks, allergies, onSubmit, loading } = props;

    const [remarkValue, setRemarkValue] = React.useState(remarks || '');
    const [allergiesValue, setAllergiesValue] = React.useState(allergies || '');

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { patch, addPatch } = React.useContext(PatchContext);

    const handleSubmit = () => {
        if (patch) onSubmit(patch);
    };

    const handleRemarkChange = (value: string) => {
        setRemarkValue(value);
        addPatch('/remarks', value);
    };
    const handleAllergiesChange = (value: string) => {
        setAllergiesValue(value);
        addPatch('/allergies', value);
    };

    return (
        <div>
            <Box
                padding={4}
                mb={4}
                rounded='lg'
                border='2px solid'
                borderColor='NorskTur.Sand'
            >
                <FormControl>
                    <Heading size='sm' mb={2} fontWeight='600'>
                        Allergier
                    </Heading>

                    <Button
                        pl='0'
                        variant='linkButton'
                        onClick={onOpen}
                        fontWeight='400'
                    >
                        <Icon mr={2} /> Informasjon om allergier
                    </Button>

                    <FormLabel mt={4}>
                        Allergier og helserelaterte merknader
                    </FormLabel>
                    <Textarea
                        borderColor='NorskTur.Navy'
                        maxW={{ base: '100%', md: '30%' }}
                        value={allergiesValue}
                        onChange={(e) => handleAllergiesChange(e.target.value)}
                    />

                    <FormLabel mt={4}>Andre merknader</FormLabel>
                    <Textarea
                        borderColor='NorskTur.Navy'
                        maxW={{ base: '100%', md: '30%' }}
                        value={remarkValue}
                        onChange={(e) => handleRemarkChange(e.target.value)}
                    />

                    <HStack
                        spacing={6}
                        display='block'
                        textAlign='end'
                        bottom={8}
                        right={8}
                        mt={{ base: '4rem', md: '0' }}
                    >
                        <Button
                            variant='primary'
                            onClick={handleSubmit}
                            isLoading={loading}
                        >
                            Lagre
                        </Button>
                    </HStack>
                </FormControl>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg='NorskTur.Sand'>
                    <ModalHeader>Info allergi</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Her kan du registrere matallergier eller andre relevante
                        helsemerknader i forhold til din reise. <br />
                        <br />
                        Denne informasjonen er ikke obligatorisk. <br />
                        <br />
                        Merk at allergier og helsemerknader regnes som sensitive
                        personopplysninger og vi sikrer disse spesielt i våre
                        systemer slik at de ikke kommer i uvedkommendes hender.
                        Innholdet i feltet `&quot;`Allergier og helserelaterte
                        merknader`&quot;` vil i tillegg bli automatisk slettet
                        en måned etter siste hjemkomst av sikkerhetsmessige
                        årsaker.
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='primary' mr={3} onClick={onClose}>
                            Lukk
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}
