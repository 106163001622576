import React from 'react';
import { Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

type NavLinkProps = {
    name: string;
    path: string;
};

export const NavLink = (props: NavLinkProps) => {
    const { name, path } = props;
    return (
        <Button
            as={RouterLink}
            to={path}
            fontWeight={400}
            height='100%'
            color='none'
            variant='link'
            _hover={{
                color: 'NorskTur.Sand',
            }}
        >
            {name}
        </Button>
    );
};
