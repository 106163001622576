import React from 'react';
import {
    Box,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react';
import { env } from 'env';
import { SearchIcon } from '../assets/searchIcon';

export default function SearchButton() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [value, setValue] = React.useState('');
    const handleChange = (event: React.FormEvent<HTMLInputElement>) =>
        setValue(event.currentTarget.value);

    const baseUrl = env.REACT_APP_WEBSITE_BASE_URL || '';

    function handleSubmit() {
        window.location.replace(`${baseUrl}/?s=${value}`);
    }

    return (
        <div>
            <Box
                ml={10}
                onClick={onOpen}
                display={{ base: 'none', lg: 'flex' }}
            >
                <Icon
                    as={SearchIcon}
                    boxSize={6}
                    color='NorskTur.Navy'
                    height='100%'
                    transition='.3s ease-in-out'
                    _hover={{ color: 'NorskTur.Sand' }}
                />
            </Box>
            <Modal
                onClose={onClose}
                size='full'
                isOpen={isOpen}
                motionPreset='scale'
            >
                <ModalOverlay />
                <ModalContent borderRadius={0}>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box
                            minH='calc(100vh)'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                        >
                            <Box
                                width='500px'
                                display='inline-block'
                                position='relative'
                            >
                                <Input
                                    placeholder='Søk'
                                    fontSize={48}
                                    textAlign='center'
                                    border='none'
                                    _focus={{}}
                                    value={value}
                                    onChange={handleChange}
                                    onKeyPress={(e) =>
                                        e.key === 'Enter' && handleSubmit()
                                    }
                                />
                            </Box>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
}
