import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Link, Button } from '@chakra-ui/react';

export function ReturnLink(props: { to?: string }) {
    const { to } = props;
    const navigate = useNavigate();
    if (to) {
        return (
            <Link as={RouterLink} to={to}>
                {' '}
                <ArrowBackIcon /> Tilbake
            </Link>
        );
    }
    return (
        <Button
            variant='link'
            color='inherit'
            fontWeight='normal'
            onClick={() => navigate(-1)}
        >
            <ArrowBackIcon /> Tilbake
        </Button>
    );
}
