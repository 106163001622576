import React from 'react';
import { Container, Divider, Grid, Box } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { IBookedTrip } from 'common/interfaces/trip';
import { useAllTrip } from '../../api/hooks/ApiHooks';
import { useAuthContext } from '../context/authContext';
import { StandardSpinner } from '../spinner/standarsSpinner';
import { formatSingleDate } from '../utilities/parser';
import PlannedTripCard from './plannedTripCard';

export default function PlannedTripsContainer() {
    const auth = useAuthContext();
    const [_, setSearchParams] = useSearchParams();

    const [activeCardId, setActive] = React.useState('');

    const { data: crmTrip, isFetching, isIdle } = useAllTrip(auth);

    const onPlannedCardClicked = React.useCallback(
        (selectedTrip: IBookedTrip) => {
            if (activeCardId === selectedTrip.tripId) {
                setActive('');
                setSearchParams({});
            } else {
                setActive(selectedTrip.tripId);
                setSearchParams({
                    'tripNumber': selectedTrip.tripNumber.toString(),
                });
            }
        },
        [activeCardId, setSearchParams]
    );

    return (
        <Container maxW='container.xl'>
            {isFetching || isIdle ? (
                <StandardSpinner title='Henter dine turer' />
            ) : (
                crmTrip &&
                crmTrip.map((cardData: IBookedTrip, index: number) => (
                    <Box display='inline' key={cardData.tripId}>
                        {index !== 0 && activeCardId === cardData.tripId && (
                            <Divider marginY={6} />
                        )}
                        <Grid
                            templateColumns={{
                                base: '1fr',
                                md:
                                    activeCardId === cardData.tripId
                                        ? 'repeat(2, 1fr)'
                                        : '1fr',
                                lg:
                                    activeCardId === cardData.tripId
                                        ? 'repeat(3, 1fr)'
                                        : '1fr',
                            }}
                            gridAutoRows='1fr'
                            marginRight={6}
                            marginBottom={6}
                            gap={6}
                            key={cardData.tripId}
                            display='inline-grid'
                        >
                            {cardData && (
                                <PlannedTripCard
                                    isActive={activeCardId === cardData.tripId}
                                    onClick={() =>
                                        onPlannedCardClicked(cardData)
                                    }
                                    title={
                                        cardData.destination?.destinationName ||
                                        'Mangler Destinasjon'
                                    }
                                    date={
                                        cardData.departureDate &&
                                        formatSingleDate(
                                            cardData.departureDate,
                                            false
                                        )
                                    }
                                    tripNumber={cardData.tripNumber.toString()}
                                    tripId={cardData.tripId}
                                    bookingReference={
                                        cardData.bookingReference || ''
                                    }
                                />
                            )}
                        </Grid>
                        {index !== crmTrip.length &&
                            activeCardId === cardData.tripId && (
                                <Divider marginBottom={12} />
                            )}
                    </Box>
                ))
            )}
        </Container>
    );
}
