import React from 'react';
import {
    Heading,
    Box,
    Flex,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useBreakpointValue,
    Button,
} from '@chakra-ui/react';
import moment from 'moment';
import 'moment/locale/nb';
import { Program } from 'common/interfaces/wpPostData';
import { getDateFromString } from '../utilities/parser';
import ScheduleTimeTable from './scheduleTimeTable';

type ScheduleContainerProps = {
    schedule: Program[];
    tripDate: string;
    tripTitle: string;
};

export default function ScheduleContainer(props: ScheduleContainerProps) {
    const [key, setKey] = React.useState(0);
    const [loadingPrint, setLoadingPrint] = React.useState(false);

    const { schedule, tripDate, tripTitle } = props;

    moment.locale('nb');

    const orientation = useBreakpointValue<'vertical' | 'horizontal'>({
        base: 'vertical',
        md: 'horizontal',
    });

    function calculateDate(index: number): string {
        const date = getDateFromString(tripDate);
        return `${moment(date).add(index, 'day').format('DD. MMM')}`;
    }

    const runPrint = () => {
        setLoadingPrint(true);
        const content = document.getElementById('section-to-print');
        const iframeToPrint = (
            document.getElementById('iframe-to-print') as HTMLIFrameElement
        )?.contentWindow;
        let stylesHtml = '';
        document
            .querySelectorAll('link[rel="stylesheet"], style')
            .forEach((element) => {
                stylesHtml += element.outerHTML;
            });
        if (iframeToPrint) {
            window.addEventListener('message', (e) => {
                if (e.data === 'Iframe loaded') {
                    setLoadingPrint((load) => {
                        if (load) {
                            iframeToPrint.print();
                        }
                        return false;
                    });
                }
            });
            iframeToPrint.document.open();
            iframeToPrint.document.write(`
                <!DOCTYPE html>
                <html>
                    <head>
                        ${stylesHtml}
                    </head>
                    <body>
                        ${content?.innerHTML}
                    </body>
                    <script>
                        window.parent.postMessage('Iframe loaded', '*')
                    </script>
                </html>`);
            iframeToPrint.document.close();
            iframeToPrint.focus();
        }
    };

    React.useEffect(() => {
        const daysUntilTrip = moment().diff(tripDate, 'days');
        if (daysUntilTrip > 0 && daysUntilTrip <= schedule.length) {
            setKey(daysUntilTrip);
        }
    }, [schedule.length, tripDate]);

    // We assume that if no info is added for 1st item, then there is none at all
    if (!schedule[0].program_timetable) {
        return <Box>Dagsprogrammet er ikke tilgjengelig enda</Box>;
    }

    return (
        <Box>
            <iframe
                title='dagsprogram'
                id='iframe-to-print'
                style={{ height: '0px', width: '0px', position: 'absolute' }}
            />
            <Button
                color='NorskTur.Navy'
                textDecoration='underline'
                _hover={{ textDecoration: 'none' }}
                variant='link'
                mt={2}
                onClick={runPrint}
                isLoading={loadingPrint}
            >
                Last ned dagsprogram
            </Button>
            <Flex mt={6}>
                <Tabs orientation={orientation} variant='unstyled' index={key}>
                    <TabList>
                        {schedule.map((content, index: number) => (
                            <Tab
                                minWidth={24}
                                key={content.day_index}
                                py={4}
                                rounded='lg'
                                roundedTopEnd={{ base: '0', md: 'lg' }}
                                roundedBottomEnd='0'
                                roundedBottomStart={{ base: 'lg', md: '0' }}
                                bg={index === key ? 'NorskTur.Sand' : 'white'}
                                onClick={() => {
                                    setKey(index);
                                }}
                            >
                                <Box>
                                    <Heading
                                        fontWeight={
                                            key === index ? '600' : '400'
                                        }
                                        size='sm'
                                    >
                                        Dag {index + 1}
                                    </Heading>
                                    <Heading
                                        fontWeight={
                                            key === index ? '600' : '400'
                                        }
                                        size='xs'
                                    >
                                        {calculateDate(index)}
                                    </Heading>
                                </Box>
                            </Tab>
                        ))}
                    </TabList>
                    <TabPanels
                        bg='NorskTur.Sand'
                        px={16}
                        pb={24}
                        minWidth={{ base: '0', md: '70vw' }}
                        roundedEnd='lg'
                        roundedTopStart={key === 0 ? '' : 'lg'}
                        roundedBottomStart='lg'
                    >
                        {schedule.map((content) => (
                            <TabPanel key={content.day_index} p={0}>
                                <Heading size='md' pt={8}>
                                    {content.title}
                                </Heading>
                                <ScheduleTimeTable
                                    content={content.program_timetable}
                                />
                            </TabPanel>
                        ))}
                    </TabPanels>
                </Tabs>
            </Flex>
            <Box id='section-to-print' display='none'>
                <Heading size='lg' mt={8}>
                    {tripTitle}
                </Heading>
                {schedule.map((content, index) => (
                    <Box key={content.day_index}>
                        <Heading size='md' mt={16}>
                            {' '}
                            Dag {index + 1} - {content.title}
                        </Heading>
                        <ScheduleTimeTable
                            content={content.program_timetable}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
