/* eslint-disable @typescript-eslint/no-shadow */
import { AxiosResponse } from 'axios';
import { Patch } from 'common/context/patchContext';
import {
    IBookedOptionalAddition,
    ITripOptionalAddition,
    IUpdateOptionalAdditions,
} from 'common/interfaces/addition';
import { IContact } from 'common/interfaces/contact';
import { IDocument } from 'common/interfaces/document';
import { IFlight } from 'common/interfaces/flight';
import { IInvoice } from 'common/interfaces/invoice';
import {
    AdditionReport,
    FlightReport,
    RoomReport,
    TravellerReport,
} from 'common/interfaces/reports';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { env } from 'env';
import { AuthContextType } from '../../common/context/authContext';
import {
    IBookedRoom,
    IBookedTrip,
    IFullTrip,
} from '../../common/interfaces/trip';
import { sortTripByDepartureDate } from '../../common/utilities/sort';
import { crmApiClient, downloadClient, smsClient } from '../axiosClient';

export const useTrip = (auth: AuthContextType, tripNumber?: string | null) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        ['get-crm-trip', tripNumber],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<IBookedTrip[]>(
                '/bookedtrip'
            );
            const trip = data;
            if (tripNumber) {
                return trip.find(
                    (t) => t.tripNumber?.toString() === tripNumber
                );
            }

            trip.sort((tripA: IBookedTrip, tripB: IBookedTrip) => {
                return sortTripByDepartureDate(tripA, tripB);
            });
            return trip[0];
        },
        { enabled: !!auth.user?.access_token }
    );
    return { data, isFetching, isIdle, isError };
};

export const useAllTrip = (auth: AuthContextType) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        ['get-all-crm-trip'],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<IBookedTrip[]>(
                '/bookedtrip'
            );

            return data;
        },
        { enabled: !!auth.user?.access_token }
    );
    return { data, isFetching, isIdle, isError };
};

export const useTripById = (auth: AuthContextType, tripId?: string) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        ['get-crm-trip-by-id', tripId],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<IFullTrip>(
                `/trip/${tripId}`
            );

            return data;
        },
        { enabled: !!tripId && !!auth.user?.access_token }
    );
    return { data, isFetching, isIdle, isError };
};

export const useFlight = (auth: AuthContextType, tripId?: string) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        ['get-crm-flight', tripId],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<IFlight>(
                `/flight/${tripId}`
            );

            return data;
        },
        { enabled: !!tripId && !!auth.user?.access_token }
    );
    return { data, isFetching, isIdle, isError };
};

export const useInvoice = (auth: AuthContextType, tripId?: string) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        ['get-crm-invoice', tripId],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<IInvoice[]>(
                `/invoice/${tripId}`
            );

            return data;
        },
        { enabled: !!tripId && !!auth.user?.access_token }
    );
    return { data, isFetching, isIdle, isError };
};

export const useAdditions = (auth: AuthContextType, tripId: string) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        ['get-crm-optional-additions', tripId],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<
                ITripOptionalAddition[]
            >(`/trip/${tripId}/optional-additions`);

            return data;
        },
        { enabled: !!tripId && !!auth.user?.access_token }
    );
    return { data, isFetching, isIdle, isError };
};

export const useBookedAdditions = (
    auth: AuthContextType,
    bookingReference?: string
) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        ['get-crm-booked-additions', bookingReference],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<
                IBookedOptionalAddition[]
            >(`/BookedOptionalAddition/${bookingReference}`);

            return data;
        },
        {
            enabled: !!bookingReference && !!auth.user?.access_token,
        }
    );
    return { data, isFetching, isIdle, isError };
};

export const useBookedRooms = (
    auth: AuthContextType,
    bookingReference?: string
) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        ['get-crm-booked-room', bookingReference],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<IBookedRoom[]>(
                `/bookedRooms/${bookingReference}`
            );
            return data[0];
        },
        { enabled: !!bookingReference && !!auth.user?.access_token }
    );
    return { data, isFetching, isIdle, isError };
};

export const useContact = (
    auth: AuthContextType,
    options?: { onSuccess?: (data: IContact) => void }
) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        ['get-crm-contact'],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<IContact>(
                '/contact'
            );

            return data;
        },
        {
            enabled: !!auth.user?.access_token,
            onSuccess: (data) => {
                if (options?.onSuccess) options.onSuccess(data);
            },
        }
    );
    return { data, isFetching, isIdle, isError };
};

export const useContactsByBookingRef = (
    auth: AuthContextType,
    bookingRef?: string
) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        ['get-crm-contact-by-bookingRef', bookingRef],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<IContact[]>(
                `/contacts/${bookingRef}`
            );

            return data;
        },
        { enabled: !!bookingRef && !!auth.user?.access_token }
    );
    return { data, isFetching, isIdle, isError };
};

export const useDocuments = (auth: AuthContextType, tripId?: string) => {
    const { data, isFetching, isIdle, isError, refetch } = useQuery(
        ['get-documents', tripId],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<IDocument[]>(
                `/document/${tripId}`
            );

            return data;
        },
        { enabled: !!auth.user?.access_token && !!tripId }
    );
    return { data, isFetching, isIdle, isError, refetch };
};
export const useTravellerReport = (auth: AuthContextType, tripId?: string) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        ['get-traveller-report', tripId],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<TravellerReport[]>(
                `/travellerreport/${tripId}`
            );

            return data.sort((travellerA, travellerB) => {
                if (
                    (travellerA.contact?.lastName ?? '') >
                    (travellerB.contact?.lastName ?? '')
                )
                    return 1;
                return -1;
            });
        },
        { enabled: !!auth.user?.access_token && !!tripId }
    );
    return { data, isFetching, isIdle, isError };
};

export const useRoomListReport = (auth: AuthContextType, tripId?: string) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        ['get-roomlist-report', tripId],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<RoomReport[]>(
                `/roomlistreport/${tripId}`
            );

            // Should ideally be done in the API, but its not, so we do it ourselves...
            const res = {
                totals: data.map((report) => ({
                    roomCategory: report.roomCategory,
                    noOfTravellers: report.noOfTravellers,
                    noOfRooms: report.noOfRooms,
                })),
                rooms: data
                    .flatMap((report) => {
                        return report.rooms?.map((room) => {
                            return {
                                contacts: room.contact,
                                roomCategory: report.roomCategory,
                                roomName: room.roomName,
                            };
                        });
                    })
                    .filter(Boolean) // filter out any empty arrays
                    .sort((a, b) => {
                        const aLastName = a?.contacts?.[0]?.lastName ?? '';
                        const bLastName = b?.contacts?.[0]?.lastName ?? '';
                        if (aLastName < bLastName) return -1;
                        if (aLastName > bLastName) return 1;
                        return 0;
                    }),
            };
            return res;
        },
        { enabled: !!auth.user?.access_token && !!tripId }
    );
    return { data, isFetching, isIdle, isError };
};

export const useAdditionReport = (auth: AuthContextType, tripId?: string) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        ['get-addition-report', tripId],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<AdditionReport[]>(
                `/optionaladditionreport/${tripId}`
            );

            return data.sort((a, b) => {
                if (
                    (a.contact[0]?.lastName ?? '') >
                    (b.contact[0]?.lastName ?? '')
                )
                    return 1;
                return -1;
            });
        },
        { enabled: !!auth.user?.access_token && !!tripId }
    );
    return { data, isFetching, isIdle, isError };
};

export const useFlightReport = (auth: AuthContextType, tripId?: string) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        ['get-flight-report', tripId],
        async () => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).get<FlightReport[]>(
                `/flightbookingreport/${tripId}`
            );

            return data.map(
                ({
                    contacts,
                    flightDetails,
                    inboundPnr,
                    outboundPnr,
                    noOfTravellers,
                }) => {
                    return {
                        contacts: contacts.sort((a, b) =>
                            (a?.lastName ?? '') > (b?.lastName ?? '') ? 1 : -1
                        ),
                        flightDetails,
                        inboundPnr,
                        outboundPnr,
                        noOfTravellers,
                    };
                }
            );
        },
        { enabled: !!auth.user?.access_token && !!tripId }
    );
    return { data, isFetching, isIdle, isError };
};

export const useUpdateAdditions = (
    auth: AuthContextType,
    options?: { onSuccess?: (data: AxiosResponse<IContact>) => void }
) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (body: IUpdateOptionalAdditions) => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).put(
                '/bookedOptionalAddition',
                body
            );

            return data;
        },
        {
            onSuccess: (data) => {
                if (data) {
                    queryClient.setQueryData(
                        ['get-crm-booked-additions'],
                        data
                    );
                }
                if (options?.onSuccess) {
                    options.onSuccess(data);
                }
            },
        }
    );
};

export const useUpdateUserInfo = (
    auth: AuthContextType,
    id: string,
    options?: {
        onSuccess?: (data: AxiosResponse<IContact>) => void;
        onSettled?: () => void;
    }
) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (body: Patch[]) => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).patch(
                `/contact/${id}`,
                body
            );

            return data;
        },
        {
            onSuccess: (data) => {
                if (data) {
                    queryClient.setQueryData(['get-crm-contact'], data);
                }
                if (options?.onSuccess) {
                    options.onSuccess(data);
                }
            },
            onSettled: () => {
                if (options?.onSettled) {
                    options.onSettled();
                }
            },
        }
    );
};

export const useUpdatePassword = (
    auth: AuthContextType,
    username: string,
    options?: {
        onSuccess?: (data: AxiosResponse<IContact>) => void;
        onSettled?: () => void;
    }
) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (body: { oldPassword: string; newPassword: string }) => {
            const token = auth.user?.access_token || '';
            const { data } = await crmApiClient(token).post(
                '/user/changepassword',
                { ...body, username }
            );
            return data;
        },
        {
            onSuccess: (data) => {
                if (data) {
                    queryClient.setQueryData(['get-crm-contact'], data);
                }
                if (options?.onSuccess) {
                    options.onSuccess(data);
                }
            },
            onSettled: () => {
                if (options?.onSettled) {
                    options.onSettled();
                }
            },
        }
    );
};

export const useReportDownload = (
    auth: AuthContextType,
    tripId: string,
    reportName: string
) => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<Error>();

    const startDownload = React.useCallback(() => {
        if (!auth.user) return;

        setLoading(true);

        downloadClient(auth.user.access_token)
            .post(`/ReportPdf/${tripId}`, {
                reportName,
            })
            .then((res: any) => {
                const url = window.URL.createObjectURL(
                    new Blob([res.data], { type: 'application/pdf' })
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', reportName.replaceAll('.', '-'));
                document.body.appendChild(link);
                link.click();
            })
            .finally(() => setLoading(false))
            .catch((e: Error) => setError(e));
    }, [auth.user, reportName, tripId]);

    return { startDownload, loading, error };
};

export const useSMS = (
    auth: AuthContextType,
    options?: { onSuccess?: (data: AxiosResponse) => void }
) => {
    return useMutation(
        async (param: { recipientList: string[]; message: string }) => {
            const body = {
                from: env.REACT_APP_SMS_FROM || 'Norsk Tur',
                body: param.message,
                recipients: param.recipientList,
            };
            const token = auth.user?.access_token || '';
            const { data } = await smsClient(token).post('/sms/send', body);
            return data;
        },
        {
            onSuccess: (data) => {
                if (options?.onSuccess) options.onSuccess(data);
            },
        }
    );
};

export const useAuthGuard = <T>(
    value: T,
    permitted: string[],
    identityType?: string
): T | undefined => {
    return React.useMemo(() => {
        if (identityType && permitted.includes(identityType)) return value;
        return undefined;
    }, [identityType, permitted, value]);
};
