import React from 'react';
import {
    Box,
    Flex,
    HStack,
    Menu,
    Image,
    Container,
    Link,
} from '@chakra-ui/react';
import Logo from '../assets/Logo-Norsk-Tur-Full.svg';
import { LinksDrawer } from './linksDrawer';
import { MyPageDrawer } from './myPageDrawer';
import { NavElement } from './navElement';
import Search from './search';

const Links = [
    {
        name: 'For bedrift',
        sub: [
            {
                name: 'Firmaturer',
                path: '/firmaturer',
            },
            {
                name: 'Venneturer',
                path: '/venneturer',
            },
            {
                name: 'Fornøyde kunder',
                path: '/fornoyde-kunder',
            },
        ],
    },
    {
        name: 'Gruppereiser',
        sub: [
            {
                name: 'Lesereiser',
                path: '/leserreiser',
            },
            {
                name: 'Reisekalender',
                path: '/reiser',
            },
            {
                name: 'Koronasertifikat',
                path: '/koronasertifikat',
            },
        ],
    },
    {
        name: 'Reisemål',
        path: '/reisemal',
    },
    {
        name: 'Om oss',
        sub: [
            {
                name: 'Om Norsk Tur',
                path: '/om-oss',
            },
            {
                name: 'Bærekraft',
                path: '/baerekraft-i-norsk-tur',
            },
            {
                name: 'Personvern',
                path: '/privacy-policy',
            },
            {
                name: 'Reisevilkår',
                path: '/reisevilkar',
            },
        ],
    },
    {
        name: 'Kontakt',
        path: '/kontakt-oss',
    },
];

export function NavBarContainer() {
    return (
        <Box h='5.5rem' mt={4}>
            <Container maxW='container.xl' h='100%'>
                <Flex alignItems='baseline' justifyContent='space-between'>
                    <Flex
                        alignItems='center'
                        alignSelf='center'
                        display={{ base: 'flex', lg: 'none' }}
                    >
                        <Menu>
                            <LinksDrawer />
                        </Menu>
                    </Flex>
                    <Link href='https://norsktur.no/' alignSelf='center'>
                        <Image
                            boxSize='180px'
                            height='auto'
                            src={Logo}
                            alt='Logo'
                        />
                    </Link>
                    <HStack
                        as='nav'
                        spacing={10}
                        height='100%'
                        display={{ base: 'none', lg: 'flex' }}
                        m='auto'
                        mr={20}
                    >
                        {Links.map((link) => (
                            <NavElement
                                key={link.name}
                                name={link.name}
                                path={link.path}
                                sub={link.sub}
                            />
                        ))}
                    </HStack>
                    <Flex alignItems='center' alignSelf='center'>
                        <Menu>
                            <MyPageDrawer />
                        </Menu>
                        <Search />
                    </Flex>
                </Flex>
            </Container>
        </Box>
    );
}
