import { User } from 'oidc-client';
import React, { createContext, useContext, useState, useMemo } from 'react';

export type AuthContextType = {
    user: User | undefined;
    setUser: (user: User | undefined) => void;
};

const defaultState: AuthContextType = {
    user: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUser: () => {},
};

const AuthContext = createContext<AuthContextType>(defaultState);

export const AuthContextProvier: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [auth, setAuth] = useState<User | undefined>(defaultState.user);

    const setUser = (user: User | undefined) => {
        setAuth(user);
    };

    const value: AuthContextType = useMemo(
        () => ({
            user: auth,
            setUser,
        }),
        [auth]
    );

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export function useAuthContext(): AuthContextType {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error(
            'useContext must be used within a Authcontext provider'
        );
    }
    return context;
}
