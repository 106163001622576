import React from 'react';
import { Button, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function ConfirmPayment() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [statusMessage, setStatusMessage] = React.useState('');
    const [paymentSucceeded, setPaymentSucceeded] = React.useState<boolean>();

    React.useEffect(() => {
        if (searchParams.get('paymentid')) {
            setPaymentSucceeded(true);
            setStatusMessage('Betalingen er utført!');
        } else {
            setPaymentSucceeded(false);
            setStatusMessage(
                'Ta kontakt med din kortutsender (f.eks banken din) for mer informasjon'
            );
        }
    }, [searchParams]);

    const returnToPayment = () => {
        navigate(`/betaling?invoiceId=${searchParams.get('uuid')}`);
    };

    return (
        <Container maxW='container.sm'>
            <Heading my={8}>{statusMessage}</Heading>
            {!paymentSucceeded && (
                <Text fontWeight='bold' color='NorskTur.Varselrød'>
                    Feil ved betaling:{' '}
                </Text>
            )}
            <Flex mt={12} justifyContent='space-between'>
                <Button
                    variant={paymentSucceeded ? 'primary' : 'inversed'}
                    onClick={() => navigate('/')}
                >
                    Lukk
                </Button>
                {paymentSucceeded === false && (
                    <Button variant='primary' onClick={returnToPayment}>
                        Gå tilbake til betalingen
                    </Button>
                )}
            </Flex>
        </Container>
    );
}
