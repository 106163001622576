import { DownloadIcon } from '@chakra-ui/icons';
import { ListItem, ListIcon, Button, Text } from '@chakra-ui/react';
import { downloadClient } from 'api/axiosClient';
import { useTrip } from 'api/hooks/ApiHooks';
import { useAuthContext } from 'common/context/authContext';
import { IDocument } from 'common/interfaces/document';
import { StandardSpinner } from 'common/spinner/standarsSpinner';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const DownloadFileListElement = (props: { file: IDocument }) => {
    const auth = useAuthContext();
    const [searchParams] = useSearchParams();

    const { data: trip } = useTrip(auth, searchParams.get('tripNumber'));
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<Error>();

    const { file } = props;

    const handleDownload = () => {
        if (auth.user && trip?.tripId) {
            setLoading(true);
            downloadClient(auth.user.access_token)
                .get(`/document/${trip.tripId}/documents/${file.id}`)
                .then((res: any) => {
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file.name);
                    document.body.appendChild(link);
                    setLoading(false);
                    link.click();
                })
                .catch((e: Error) => setError(e));
        }
    };

    return (
        <ListItem onClick={handleDownload}>
            {loading ? <StandardSpinner /> : <ListIcon as={DownloadIcon} />}
            <Button
                px={0}
                maxW={64}
                overflow='hidden'
                whiteSpace='nowrap'
                textOverflow='ellipsis'
                display='inline-block'
                variant='linkButton'
            >
                {file.name}
            </Button>
            {error && <Text bg='NorskTur.Varselrød'>{error.message}</Text>}
        </ListItem>
    );
};
