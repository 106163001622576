import {
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
} from '@chakra-ui/react';
import React from 'react';

type Proptype = {
    header: string;
    text: string;
    primaryButtonText: string;
    secondaryButtonText: string;
    isOpen: boolean;
    onClose: () => void;
    onSuccess?: (...args: any[]) => void;
};

export default function PopupAlert(props: Proptype) {
    const cancelRef = React.useRef<HTMLButtonElement>(null);

    const {
        header,
        text,
        primaryButtonText,
        secondaryButtonText,
        onSuccess,
        isOpen,
        onClose,
    } = props;

    const handleSuccess = () => {
        if (onSuccess) onSuccess();
        onClose();
    };

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {header}
                    </AlertDialogHeader>

                    <AlertDialogBody>{text} </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            {secondaryButtonText}
                        </Button>
                        <Button
                            onClick={handleSuccess}
                            ml={3}
                            variant='primary'
                        >
                            {primaryButtonText}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}
