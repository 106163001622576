import { Button, Container, Heading, Text } from '@chakra-ui/react';
import * as React from 'react';

export class ErrorBoundary extends React.Component<
    { children: React.ReactNode },
    { hasError: boolean; message?: string }
> {
    constructor(props: { children: React.ReactNode }) {
        super(props);
        this.state = { hasError: false };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getDerivedStateFromError(_error: TypeError) {
        return { hasError: true, message: _error.message };
    }

    render() {
        const { hasError, message } = this.state;
        const { children } = this.props;

        if (hasError) {
            return (
                <Container maxW='container.md'>
                    <Heading mb={8}>Noe gikk galt!</Heading>
                    <Text>{message}</Text>
                    <Button
                        color='NorskTur.Navy'
                        textDecoration='underline'
                        _hover={{ textDecoration: 'none' }}
                        variant='link'
                        type='submit'
                        onClick={() => {
                            this.setState({ hasError: false });
                            window.location.href = '/';
                        }}
                    >
                        Tilbake
                    </Button>
                </Container>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
