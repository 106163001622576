import React from 'react';
import { Box, Heading, ListItem, UnorderedList, Text } from '@chakra-ui/react';
import HTMLReactParser from 'html-react-parser';
import { ITimeTable } from '../interfaces/trip';

type ScheduleTimeTableProps = {
    content?: ITimeTable[];
    programString?: string;
};
export default function ScheduleTimeTable(props: ScheduleTimeTableProps) {
    const { content, programString } = props;
    return (
        <Box maxW='xl'>
            {content && (
                <UnorderedList ml={0} mt={6}>
                    {content.map((timeTable, index) => (
                        <ListItem
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            listStyleType='none'
                            mb={4}
                            className='pagebreak'
                        >
                            <Heading size='sm' mb={2}>
                                {timeTable.start_time}
                                {timeTable.end_time
                                    ? ` - ${timeTable.end_time}`
                                    : ''}
                            </Heading>
                            <Text whiteSpace='pre-wrap'>
                                {timeTable.program}
                            </Text>
                        </ListItem>
                    ))}
                </UnorderedList>
            )}
            {programString && (!content || content?.length === 0) && (
                <Text mt={6} whiteSpace='pre-wrap'>
                    {HTMLReactParser(programString, { trim: true })}
                </Text>
            )}
        </Box>
    );
}
