import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { IFlightLeg } from 'common/interfaces/flight';
import { ListIcon } from 'common/assets/listIcon';
import { ITripCardData } from '../interfaces/cardData';
import CardView from './card.view';
import { useAuthContext } from '../context/authContext';
import { StandardSpinner } from '../spinner/standarsSpinner';
import { formatSingleDate } from '../utilities/parser';
import { ITripOptionalAddition } from '../interfaces/addition';
import { FlightIcon } from '../assets/flightIcon';
import { AdditionsIcon } from '../assets/additionsIcon';
import { HotelIcon } from '../assets/hotelIcon';
import { PaymentIcon } from '../assets/paymentIcon';
import {
    useAdditions,
    useAuthGuard,
    useBookedRooms,
    useContact,
    useFlight,
    useInvoice,
} from '../../api/hooks/ApiHooks';

type TripCardProps = {
    tripId: string;
    tripBookingReference: string;
};

export default function TripCards(props: TripCardProps) {
    const auth = useAuthContext();
    const { tripId, tripBookingReference } = props;

    const {
        data: invoiceData,
        isFetching: isInvoiceFetching,
        isError: isInvoiceError,
    } = useInvoice(auth, tripId);
    const {
        data: flightData,
        isFetching: isFlightFetching,
        isError: isFlightError,
    } = useFlight(auth, tripId);
    const {
        data: optionaladditionsData,
        isFetching: isAdditionFetching,
        isError: isAdditionError,
    } = useAdditions(auth, tripId);
    const {
        data: roomData,
        isFetching: isRoomFetching,
        isError: isRoomError,
    } = useBookedRooms(auth, tripBookingReference);

    const { data: contactData } = useContact(auth);

    const listCard: ITripCardData | undefined = useAuthGuard(
        {
            path: '/lister',
            secondaryPath: '/sms',
            icon: <ListIcon />,
            title: 'Reiseleder / kundekontakt',
            primaryBtnText: 'Lister',
            secondaryBtnText:
                contactData?.identityType === 'TourLeader' ||
                contactData?.identityType === 'TripAdmin'
                    ? 'SMS'
                    : undefined,
            content: (
                <Text>Her finner du informasjon og lister om reisende</Text>
            ),
        },
        ['TourLeader', 'TripAdmin', 'TourleaderCustomer'],
        contactData?.identityType
    );

    function getEarliestAdditionDeadline(additions: ITripOptionalAddition[]) {
        if (additions) {
            additions.sort(
                (a: ITripOptionalAddition, b: ITripOptionalAddition) => {
                    if (!!a.deadlineDate && !!b.deadlineDate) {
                        return (
                            new Date(a.deadlineDate).getTime() -
                            new Date(b.deadlineDate).getTime()
                        );
                    }
                    return -1;
                }
            );
        }
        return additions[0];
    }

    function getEarliestDeparture(flightLegs: IFlightLeg[]) {
        flightLegs.sort((a, b) => {
            if (!!a.departureTime && !!b.arrivalTime) {
                return (
                    new Date(a.departureTime).getTime() -
                    new Date(a.departureTime).getTime()
                );
            }
            return -1;
        });
        return flightLegs[0];
    }

    function createRoomDescString() {
        let description = '';
        if (roomData?.roomCategory) {
            description = `Du har ${roomData.roomCategory.roomCategoryName}`;

            const buddies =
                contactData && roomData.contacts
                    ? roomData.contacts.filter(
                          (contact) =>
                              contact?.contactId !== contactData.contactId
                      )
                    : undefined;

            if (buddies && buddies.length > 0) {
                description += ` sammen med ${buddies
                    .map((buddy) => `${buddy?.firstName} ${buddy?.lastName}`)
                    .join(' og ')}`;
            }
        }
        return description;
    }

    function getUpaidInvoices() {
        if (invoiceData && invoiceData.length > 0) {
            return invoiceData.filter(
                (invoice) => invoice.outstandingAmount !== 0
            );
        }
        return undefined;
    }

    function createInvoiceDescElement(): JSX.Element {
        const unpaidInvoices = getUpaidInvoices();

        if (!unpaidInvoices) return <Text>Finner ikke fakturainformasjon</Text>;

        if (unpaidInvoices.length === 0) {
            return (
                <Text>Din reise er betalt, nå er det bare å glede seg.</Text>
            );
        }
        if (unpaidInvoices.length >= 1) {
            return (
                <Text color='NorskTur.Varselrød'>
                    Du har {unpaidInvoices.length} utestående faktura
                    {unpaidInvoices.length > 1 && 'er'}
                </Text>
            );
        }
        return <Text>Finner ikke fakturainformasjon</Text>;
    }

    const showFlightCard = () => {
        if (!flightData || isFlightError) return false;
        const { outboundFlightLegs, inboundFlightLegs } = flightData;
        if (
            outboundFlightLegs &&
            outboundFlightLegs.length > 0 &&
            inboundFlightLegs &&
            inboundFlightLegs.length > 0
        )
            return true;
        return false;
    };

    function createTripCardData(): ITripCardData[] {
        const cards = [];
        const unpaidInvoices = getUpaidInvoices();

        if (invoiceData && !isInvoiceError)
            cards.push({
                path: '/faktura',
                icon: <PaymentIcon boxSize={6} notification={false} />,
                title: 'Betalinger',
                primaryBtnText:
                    unpaidInvoices && unpaidInvoices.length > 0
                        ? 'Betal med kort'
                        : 'Fakturainformasjon',
                content: createInvoiceDescElement(),
            });
        if (showFlightCard())
            cards.push({
                path: '/flyinformasjon',
                icon: <FlightIcon boxSize={6} />,
                title: 'Flyinformasjon',
                primaryBtnText: 'Se mer',
                content: (
                    <Text>
                        Utreise{' '}
                        {formatSingleDate(
                            getEarliestDeparture(
                                flightData!.outboundFlightLegs!
                            ).departureTime,
                            true
                        )}
                        <br />
                        Hjemreise{' '}
                        {formatSingleDate(
                            getEarliestDeparture(flightData!.inboundFlightLegs!)
                                .departureTime,
                            true
                        )}
                    </Text>
                ),
            });
        if (!isAdditionError)
            cards.push({
                path: '/aktiviteter',
                icon: <AdditionsIcon boxSize={6} />,
                title: 'Påmelding aktiviteter',
                primaryBtnText: optionaladditionsData && 'Se/endre aktiviteter',
                content: optionaladditionsData ? (
                    <Text>
                        Husk å melde deg på aktiviteter{' '}
                        {optionaladditionsData &&
                            `før ${formatSingleDate(
                                getEarliestAdditionDeadline(
                                    optionaladditionsData
                                ).deadlineDate,
                                false
                            )}`}
                    </Text>
                ) : (
                    <Text>
                        Det er ikke satt opp noen aktiviteter på denne turen
                        enda
                    </Text>
                ),
            });
        if (roomData && !isRoomError)
            cards.push({
                path: '/hotell',
                icon: <HotelIcon boxSize={6} />,
                title: 'Rom og hotell',
                primaryBtnText: roomData && 'Se mer',
                content: roomData ? (
                    <Text>{createRoomDescString()}</Text>
                ) : (
                    <Text>
                        Det er ikke tilgjengelig informasjon om rom og hotell
                        enda
                    </Text>
                ),
            });
        if (listCard) cards.push(listCard);

        return cards;
    }

    const isLoading =
        isInvoiceFetching ||
        isFlightFetching ||
        isAdditionFetching ||
        isRoomFetching;
    if (isLoading) {
        return (
            <Box padding={8} pl={0} margin='auto'>
                <StandardSpinner title='Henter turinfo' />
            </Box>
        );
    }
    const tripCardData = createTripCardData();

    return (
        <>
            {tripCardData.map((card: ITripCardData) => (
                <CardView
                    path={card.path}
                    icon={card.icon}
                    title={card.title}
                    primaryBtnText={card.primaryBtnText}
                    secondaryBtnText={card.secondaryBtnText}
                    secondaryPath={card.secondaryPath}
                    cardContent={card.content}
                    key={card.title}
                />
            ))}
        </>
    );
}
