/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { ReturnLink } from 'common/nav/returnLink';
import ScheduleContainer from './scheduleContainer';
import { StandardSpinner } from '../spinner/standarsSpinner';
import { useAuthContext } from '../context/authContext';
import { useWpTrip } from '../../api/hooks/WordpressHooks';
import { useTrip } from '../../api/hooks/ApiHooks';

export default function DailyScheduleContainer() {
    const auth = useAuthContext();
    const [searchParams] = useSearchParams();

    const { data: crmTrip } = useTrip(auth, searchParams.get('tripNumber'));
    const {
        data: wpTripInfo,
        isFetching,
        isIdle,
        isError,
    } = useWpTrip(crmTrip?.tripNumber.toString());

    return (
        <Container maxW='container.xl'>
            <Box mb={4}>
                <ReturnLink />
            </Box>
            {isFetching || isIdle ? (
                <StandardSpinner title='Henter dagsprogram' />
            ) : !isError && wpTripInfo ? (
                <div>
                    <Box mb={6}>
                        <Heading size='lg' my={2}>
                            Dagsprogram
                        </Heading>
                        <Text>{crmTrip?.tripName || ''}</Text>
                    </Box>
                    {wpTripInfo.program ? (
                        <ScheduleContainer
                            schedule={wpTripInfo.program}
                            tripDate={wpTripInfo.departure_date}
                            tripTitle={crmTrip?.tripName || ''}
                        />
                    ) : (
                        <Box>Dagsprogrammet er ikke tilgjengelig enda</Box>
                    )}
                </div>
            ) : (
                <Text>Fant ikke turen din</Text>
            )}
        </Container>
    );
}
