declare global {
    interface Window {
        // eslint-disable-next-line
        env: any;
    }
}

// change with your own variables
type EnvType = {
    REACT_APP_CONTENT_API: string;
    REACT_APP_WEBSITE_BASE_URL: string;
    REACT_APP_CRM_API: string;
    REACT_APP_OIDC_AUTHORITY: string;
    REACT_APP_OIDC_CLIENT: string;
    REACT_APP_OIDC_SECRET: string;
    REACT_APP_ACCOUNT_NUMBER: string;
    REACT_APP_PAYMENT_URL: string;
    REACT_APP_SMS_API: string;
    REACT_APP_SMS_FROM: string;
};
export const env: EnvType = { ...process.env, ...window.env };
