import React from 'react';
import { Box, Button, Divider, Heading, HStack, Text } from '@chakra-ui/react';
import { IContact } from '../interfaces/contact';

type ProfileInfoProps = {
    onClick: () => void;
    contact: IContact;
};

export default function ProfileInfo(props: ProfileInfoProps) {
    const { onClick, contact } = props;
    return (
        <Box
            padding={8}
            mb={8}
            rounded='lg'
            border='2px solid'
            display='block'
            borderColor='NorskTur.Sand'
        >
            <Heading size='md'>
                {contact.firstName} {contact.lastName}
            </Heading>

            <Heading size='sm' mt={6} mb={2} fontWeight='semibold'>
                E-post (ditt brukernavn)
            </Heading>
            <Text>{contact.email}</Text>
            <Divider mt={2} />

            <Heading size='sm' mt={4} mb={2} fontWeight='semibold'>
                Telefon
            </Heading>
            <Text minH={6}>{contact.phoneNumber?.number || ''}</Text>
            <Divider mt={2} />

            {contact.address && (
                <div>
                    <Heading size='sm' mt={4} mb={2} fontWeight='semibold'>
                        Adresse
                    </Heading>
                    <Text minH={6}>
                        {`${contact.address.addressLine1 || ''}
                        ${contact.address.addressLine2 || ''}
                        ${contact.address.postalCode || ''}
                        ${contact.address.city || ''}`}
                    </Text>
                    <Divider mt={2} />
                </div>
            )}
            {contact.allergies && (
                <div>
                    <Heading size='sm' mt={4} mb={2} fontWeight='semibold'>
                        Allergi
                    </Heading>
                    <Text>{contact.allergies}</Text>
                </div>
            )}

            <Text mt={8}>
                Du kan endre kontaktiformasjon, passord, passinformasjon og mer
                ved å klikke på knappen under
            </Text>
            <HStack
                spacing={6}
                display='block'
                textAlign='end'
                bottom={8}
                right={8}
                mt={8}
            >
                <Button variant='primary' onClick={onClick}>
                    Endre profil
                </Button>
            </HStack>
        </Box>
    );
}
