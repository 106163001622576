import React from 'react';
import { Link, useLocation } from 'react-router-dom';

type PropTypes = {
    children: React.ReactNode;
    to: string;
};

export const LinkWithQuery = ({ children, to, ...props }: PropTypes) => {
    const { search } = useLocation();

    return (
        <Link to={to + search} {...props}>
            {children}
        </Link>
    );
};
