import moment from 'moment';

export function getTimeDiffDisplay(start: string, end: string): string {
    const diffMinutes = moment
        .duration(moment.utc(end).local().diff(moment.utc(start).local()))
        .asMinutes();

    const hours = Math.floor(diffMinutes / 60);
    const minutes = diffMinutes - hours * 60;

    return `${hours}t ${minutes}min`;
}

export function getWeekDayName(date?: Date, shortFormat?: boolean) {
    // JS Date.getDay returns in format: Sunday - Saturday | 0 - 6
    const weekdays = [
        'søndag',
        'mandag',
        'tirsdag',
        'onsdag',
        'torsdag',
        'fredag',
        'lørdag',
    ];

    if (!date) return '';

    return shortFormat
        ? weekdays[date.getDay()].substring(0, 2)
        : weekdays[date.getDay()];
}
