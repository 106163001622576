import React from 'react';
import {
    useAuthGuard,
    useContact,
    useSMS,
    useTravellerReport,
    useTrip,
} from 'api/hooks/ApiHooks';
import { useAuthContext } from 'common/context/authContext';
import { useSearchParams } from 'react-router-dom';
import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Container,
    Heading,
    Stack,
    Textarea,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { ReturnLink } from 'common/nav/returnLink';
import { StandardSpinner } from 'common/spinner/standarsSpinner';
import PopupAlert from 'common/utilities/alert';

function SMSContainer() {
    const auth = useAuthContext();
    const [searchParams] = useSearchParams();

    const { data: contact, isFetching: isFetchingContact } = useContact(auth);

    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { data: crmTrip, isFetching: isFetchingFromCRM } = useTrip(
        auth,
        searchParams.get('tripNumber')
    );

    const travellerList = useAuthGuard(
        useTravellerReport(auth, crmTrip?.tripId),
        ['TourLeader', 'TripAdmin'],
        contact?.identityType
    );

    const [markedContacts, setMarkedContacts] = React.useState<
        (string | number)[]
    >([]);
    const [message, setMessage] = React.useState('');

    const send = useSMS(auth, {
        onSuccess() {
            toast({
                title: 'SMS sendt',
            });
        },
    });

    const contacts = React.useMemo(() => {
        if (!travellerList) return [];

        const data = travellerList.data?.map((c) => c?.contact);

        const sortedContacts = data?.sort((a, b) => {
            const lastNameA = a && a.lastName ? a.lastName.toUpperCase() : '';
            const lastNameB = b && b.lastName ? b.lastName.toUpperCase() : '';
            if (lastNameA < lastNameB) {
                return -1;
            }
            if (lastNameA > lastNameB) {
                return 1;
            }
            return 0;
        });

        return (sortedContacts || []).map((c) => ({
            name: `${c?.lastName || ''}, ${c?.firstName || ''} ${
                c?.travellerType === 'TourLeader' ? '(RL)' : ''
            }`,
            mobilePhone: c?.mobilePhone || '',
        }));
    }, [travellerList]);

    const onSelectAll = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked)
                setMarkedContacts(contacts.map((c) => c.mobilePhone));
            else {
                setMarkedContacts([]);
            }
        },
        [contacts]
    );

    const onSubmit = React.useCallback(() => {
        const toSend = {
            recipientList: markedContacts as string[], // We only allow strings as value, so this is no issue
            message,
        };
        send.mutate(toSend);
        onClose();
    }, [markedContacts, message, send, onClose]);

    return (
        <Container maxW='container.xl' px={{ base: 0, md: 4 }}>
            <Box px={{ base: 4, md: 0 }}>
                <ReturnLink />
            </Box>
            {isFetchingContact ||
            isFetchingFromCRM ||
            travellerList?.isFetching ? (
                <Box p={4}>
                    <StandardSpinner title='Henter reisende...' />
                </Box>
            ) : (
                <Container maxW='container.2xl' p={4}>
                    <Heading size='lg' my={8}>
                        Send SMS til reisende for {crmTrip?.tripName}
                    </Heading>
                    <CheckboxGroup
                        value={markedContacts}
                        onChange={(value) => setMarkedContacts([...value])}
                    >
                        <Stack spacing={[1, 5]}>
                            <Checkbox onChange={onSelectAll} fontWeight='bold'>
                                Send til alle
                            </Checkbox>
                            {contacts.map((c) => (
                                <Checkbox value={c.mobilePhone}>
                                    {c.name} - {c.mobilePhone}
                                </Checkbox>
                            ))}
                        </Stack>
                    </CheckboxGroup>
                    <Box my={6}>
                        <Textarea
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder='Skriv melding'
                            h={36}
                            w={{ base: '100%', md: '50%' }}
                        />
                    </Box>
                    <Button
                        variant='primary'
                        isLoading={send.isLoading}
                        onClick={onOpen}
                    >
                        Send
                    </Button>
                </Container>
            )}

            <PopupAlert
                header='Send SMS'
                text={`Du er i ferd med å sende SMS til ${markedContacts.length} reisende. \n Ønsker du å sende meldingen?`}
                secondaryButtonText='Avbryt'
                primaryButtonText='Send'
                isOpen={isOpen}
                onClose={onClose}
                onSuccess={onSubmit}
            />
        </Container>
    );
}

export default SMSContainer;
