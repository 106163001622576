import React from 'react';
import { Box, Container, Grid, GridItem } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import TripCards from '../tripCard/tripCards';
import TripDocuments from './tripDocuments';
import TripHeader from './tripHeader';
import { StandardSpinner } from '../spinner/standarsSpinner';
import { useAuthContext } from '../context/authContext';
import { formatTripDate } from '../utilities/parser';
import { useDocuments, useTrip } from '../../api/hooks/ApiHooks';
import { useWpTrip } from '../../api/hooks/WordpressHooks';

export default function NextTrip() {
    const auth = useAuthContext();
    const [searchParams] = useSearchParams();

    const {
        data: crmTrip,
        isFetching: isFetchingFromCRM,
        isError: isCrmError,
    } = useTrip(auth, searchParams.get('tripNumber'));

    const { data: documents, isFetching: isFetchingDocuments } = useDocuments(
        auth,
        crmTrip?.tripId
    );

    const {
        data: wpTripInfo,
        isFetching: isWpFetching,
        isError: isWpError,
    } = useWpTrip(crmTrip?.tripNumber?.toString());

    // Wait for query to finish before formatting dates
    const [tripDate, setTripDate] = React.useState('');
    React.useEffect(() => {
        if (wpTripInfo) {
            setTripDate(
                formatTripDate(
                    wpTripInfo.departure_date,
                    wpTripInfo.return_date
                )
            );
        }
    }, [wpTripInfo]);

    const fetching = isWpFetching || isFetchingFromCRM;

    if (!fetching && (isCrmError || !crmTrip)) {
        return (
            <Container maxW='container.xl'>
                <Box
                    p={4}
                    mb={4}
                    color='NorskTur.Varselrød'
                    fontWeight='bold'
                    bg='NorskTur.GråLys'
                    rounded='lg'
                >
                    Fant ingen turer registrert på din bruker
                </Box>
            </Container>
        );
    }

    if (!fetching && (isWpError || !wpTripInfo)) {
        return (
            <Container maxW='container.xl'>
                <Box
                    p={4}
                    mb={4}
                    color='NorskTur.Varselrød'
                    fontWeight='bold'
                    bg='NorskTur.GråLys'
                    rounded='lg'
                >
                    Mangler informasjon om turen din
                </Box>
            </Container>
        );
    }

    return (
        <Container maxW='container.xl'>
            {fetching ? (
                <StandardSpinner title='Henter turinformasjon' />
            ) : (
                <div>
                    <Grid
                        templateColumns={{
                            base: '1fr',
                            md: 'repeat(2, 1fr)',
                            lg: 'repeat(3, 1fr)',
                        }}
                        gridAutoRows='1fr'
                        gap={4}
                    >
                        <GridItem rowSpan={2} colSpan={{ base: 1, md: 2 }}>
                            {wpTripInfo && (
                                <TripHeader
                                    title={crmTrip?.tripName || ''}
                                    dateString={tripDate}
                                    tripUrl={wpTripInfo.link}
                                    featuredImageUrl={wpTripInfo.featured_image}
                                />
                            )}
                        </GridItem>
                        {crmTrip &&
                            crmTrip.tripId &&
                            crmTrip.bookingReference && (
                                <TripCards
                                    tripId={crmTrip.tripId}
                                    tripBookingReference={
                                        crmTrip.bookingReference
                                    }
                                />
                            )}
                        <TripDocuments
                            documents={documents}
                            isLoading={isFetchingDocuments}
                            display={{ base: 'none', md: 'block' }}
                        />
                    </Grid>
                    {crmTrip && (
                        <Box mt={4} display={{ base: 'block', md: 'none' }}>
                            <TripDocuments
                                documents={documents}
                                isLoading={isFetchingDocuments}
                            />
                        </Box>
                    )}
                </div>
            )}
        </Container>
    );
}
