import React from 'react';
import { HStack, Button, Select } from '@chakra-ui/react';

type Proptypes = {
    gotoPage: (pageIndex: number) => void;
    previousPage: () => void;
    nextPage: () => void;
    canPreviousPage: boolean;
    canNextPage: boolean;
    currentPageIndex: number;
    numberOfPages: number;
    pageOptions: number[];
    pageSize: number;
    setPageSize: (size: number) => void;
};

function Paginator(props: Proptypes) {
    const {
        gotoPage,
        previousPage,
        nextPage,
        currentPageIndex,
        numberOfPages,
        canNextPage,
        canPreviousPage,
        pageOptions,
        pageSize,
        setPageSize,
    } = props;

    return (
        <HStack height={16} display='inline-block' width='70%'>
            <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
            </Button>{' '}
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
            </Button>{' '}
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
            </Button>{' '}
            <Button
                onClick={() => gotoPage(numberOfPages - 1)}
                disabled={!canNextPage}
            >
                {'>>'}
            </Button>{' '}
            <span>
                Side{' '}
                <strong>
                    {currentPageIndex + 1} av {pageOptions.length}
                </strong>{' '}
            </span>
            <Select
                display='inline-block'
                width='8rem'
                value={pageSize}
                onChange={(e) => {
                    setPageSize(Number(e.target.value));
                }}
            >
                {[10, 20, 30, 40, 50].map((size) => (
                    <option key={size} value={size}>
                        Vis {size}
                    </option>
                ))}
            </Select>
        </HStack>
    );
}

export default Paginator;
