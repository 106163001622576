import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    useDisclosure,
    UnorderedList,
    ListItem,
    IconButton,
    Link,
    Input,
    InputGroup,
    InputLeftElement,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { SearchIcon } from 'common/assets/searchIcon';

const links = [
    {
        name: 'Reisekalender',
        path: '/reiser',
    },
    {
        name: 'Leserreiser',
        path: '/leserreiser',
    },
    {
        name: 'For bedrift',
        path: '/firmaturer',
    },
    {
        name: 'Venneturer',
        path: '/venneturer',
    },
    {
        name: 'Fornøyde kunder',
        path: '/fornoyde-kunder',
    },
    {
        name: 'Om oss',
        path: '/om-oss',
    },
    {
        name: 'Kontakt oss',
        path: '/kontakt-oss',
    },
    {
        name: 'Meld deg på vårt nyhetsbrev',
        path: '/om-oss/meld-deg-pa-vart-nyhetsbrev',
    },
    {
        name: 'Bærekraft',
        path: '/baerekraft-i-norsk-tur/',
    },
    {
        name: 'Reisevilkår',
        path: '/reisevilkar',
    },
    {
        name: 'Personvern',
        path: '/privary-policy',
    },
];
export function LinksDrawer() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [searchInput, setSearchInput] = React.useState('');

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            window.location.replace(`https://norsktur.no/?s=${searchInput}`);
        }
    };

    return (
        <div>
            <IconButton
                aria-label='Åpne sidemeny'
                icon={<HamburgerIcon />}
                onClick={onOpen}
                bg='white'
                color='black'
                size='lg'
            />
            <Drawer isOpen={isOpen} placement='left' onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />

                    <DrawerBody>
                        <UnorderedList mt={12} listStyleType='none'>
                            {links.map((link) => (
                                <ListItem
                                    mt={4}
                                    _hover={{ textDecoration: 'underline' }}
                                    key={link.path}
                                >
                                    <Link
                                        href={`https://norsktur.no${link.path}`}
                                        onClick={onClose}
                                    >
                                        {link.name}
                                    </Link>
                                </ListItem>
                            ))}
                            <ListItem mt={8}>
                                <InputGroup>
                                    <InputLeftElement>
                                        <SearchIcon color='NorskTur.GråMørk' />
                                    </InputLeftElement>
                                    <Input
                                        placeholder='Søk'
                                        value={searchInput}
                                        onChange={(e) =>
                                            setSearchInput(e.target.value)
                                        }
                                        onKeyUp={handleKeyPress}
                                        bg='NorskTur.GråLys'
                                        border='none'
                                        borderRadius='full'
                                    />
                                </InputGroup>
                            </ListItem>
                        </UnorderedList>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </div>
    );
}
