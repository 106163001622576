import React from 'react';
import { Icon } from '@chakra-ui/react';

export function LuggageIcon(props: any) {
    return (
        <Icon viewBox='0 0 24 24' {...props}>
            <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M12.03 4C13.11 4 14 4.88 14 6V17C14 18.13 13.11 19 12.03 19C12.03 19.58 11.56 20 11 20C10.5 20 10 19.58 10 19H4C4 19.58 3.5 20 3 20C2.44 20 1.97 19.58 1.97 19C0.89 19 0 18.13 0 17V6C0 4.88 0.89 4 1.97 4H4V1C4 0.42 4.46 0 5 0H9C9.54 0 10 0.42 10 1V4H12.03ZM8.5 4V1.5H5.5V4H8.5ZM3 7V16H4.5V7H3ZM9.5 7V16H11V7H9.5ZM6.25 7V16H7.75V7H6.25Z'
                    fill='#25384A'
                />
            </svg>
        </Icon>
    );
}
