import React from 'react';
import {
    Box,
    Button,
    Container,
    HStack,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/authContext';
import { StandardSpinner } from '../spinner/standarsSpinner';
import EditProfileInfo from './editProfileInfo';
import ProfileHeader from './profileHeader';
import ProfileInfo from './profileInformation';
import { ReturnLink } from '../nav/returnLink';
import { useContact } from '../../api/hooks/ApiHooks';

export default function ProfileContainer() {
    const auth = useAuthContext();

    const { data: contactData, isFetching } = useContact(auth);

    const [activeEdit, setActiveEdit] = React.useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();

    const toggle = React.useCallback(() => {
        setActiveEdit((value) => !value);
    }, []);

    function routeToLogout() {
        navigate('/logout');
        onClose();
    }

    return (
        <Container maxW='container.lg'>
            <Box mb={4}>
                <ReturnLink />
            </Box>
            {isFetching ? (
                <StandardSpinner title='Henter din profil' />
            ) : (
                contactData && (
                    <div>
                        <ProfileHeader email={contactData.email || ''} />
                        <Button variant='linkButton' p={0} onClick={toggle}>
                            {activeEdit ? 'Se Profil' : 'Endre Profil'}
                        </Button>
                        {activeEdit ? (
                            <EditProfileInfo contact={contactData} />
                        ) : (
                            <ProfileInfo
                                onClick={() => setActiveEdit(true)}
                                contact={contactData}
                            />
                        )}
                        <HStack
                            spacing={6}
                            display='block'
                            textAlign='end'
                            bottom={8}
                            right={8}
                            mt={16}
                        >
                            <Button variant='inversed' onClick={onOpen}>
                                Logg ut
                            </Button>
                        </HStack>
                    </div>
                )
            )}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg='NorskTur.Sand'>
                    <ModalHeader>
                        Er du sikker på at du vil logge ut
                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalFooter>
                        <Button variant='inversed' mr={3} onClick={onClose}>
                            Avbryt
                        </Button>
                        <Button
                            variant='primary'
                            mr={3}
                            onClick={() => routeToLogout()}
                        >
                            Ja!
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    );
}
