import React from 'react';
import {
    FormControl,
    Heading,
    FormLabel,
    Input,
    HStack,
    Button,
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Icon,
    Radio,
    RadioGroup,
    Stack,
} from '@chakra-ui/react';
import { IContact } from 'common/interfaces/contact';
import { Patch, PatchContext } from 'common/context/patchContext';
import { radioCheckedStyleProps } from '../../../theme';

type PropTypes = {
    onSubmit: (patch: Patch[]) => void;
    contact: IContact;
    loading: boolean;
};

export function PersonaliaFormCard(props: PropTypes) {
    const { contact, onSubmit, loading } = props;

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [firstName, setFirstName] = React.useState(contact.firstName || '');
    const [lastName, setLastName] = React.useState(contact.lastName || '');
    const [address, setAddress] = React.useState(
        contact.address?.addressLine1 || ''
    );
    const [postalCode, setPostalCode] = React.useState(
        contact.address?.postalCode || ''
    );
    const [city, setCity] = React.useState(contact.address?.city || '');
    const [gender, setGender] = React.useState(contact.gender || '');

    const { patch, addPatch } = React.useContext(PatchContext);

    const handleFistNameChange = (value: string) => {
        setFirstName(value);
        addPatch('/firstName', value);
    };
    const handleLastNameChange = (value: string) => {
        setLastName(value);
        addPatch('/lastName', value);
    };
    const handleAddressChange = (value: string) => {
        setAddress(value);
        addPatch('/address/addressLine1', value);
    };
    const handlePostalCodeChange = (value: string) => {
        setPostalCode(value);
        addPatch('/address/postalCode', value);
    };
    const handleCityChange = (value: string) => {
        setCity(value);
        addPatch('/address/city', value);
    };
    const handleGenderChange = (value: string) => {
        setGender(value);
        addPatch('/gender', value);
    };

    function handleSubmit() {
        if (patch) onSubmit(patch);
    }

    return (
        <div>
            <Box
                padding={4}
                mb={4}
                rounded='lg'
                border='2px solid'
                borderColor='NorskTur.Sand'
            >
                <FormControl>
                    <Heading size='sm' mb={2} fontWeight='600'>
                        Personalia
                    </Heading>

                    <Button
                        pl='0'
                        variant='linkButton'
                        onClick={onOpen}
                        fontWeight='400'
                    >
                        <Icon mr={2} /> Informasjon om personalia
                    </Button>

                    <FormLabel>Fornavn</FormLabel>
                    <Input
                        id='first_name'
                        borderColor='NorskTur.Navy'
                        maxW={{ base: '100%', md: '30%' }}
                        value={firstName}
                        onChange={(e) => handleFistNameChange(e.target.value)}
                    />

                    <FormLabel mt={4}>Etternavn</FormLabel>
                    <Input
                        id='last_name'
                        borderColor='NorskTur.Navy'
                        maxW={{ base: '100%', md: '30%' }}
                        value={lastName}
                        onChange={(e) => handleLastNameChange(e.target.value)}
                    />

                    <FormLabel mt={4}>Adresse</FormLabel>
                    <Input
                        id='address'
                        borderColor='NorskTur.Navy'
                        maxW={{ base: '100%', md: '30%' }}
                        value={address}
                        onChange={(e) => handleAddressChange(e.target.value)}
                    />

                    <FormLabel mt={4}>Postnummer</FormLabel>
                    <Input
                        id='postal_code'
                        borderColor='NorskTur.Navy'
                        maxW={{ base: '100%', md: '30%' }}
                        value={postalCode}
                        onChange={(e) => handlePostalCodeChange(e.target.value)}
                    />

                    <FormLabel mt={4}>Sted</FormLabel>
                    <Input
                        id='city'
                        borderColor='NorskTur.Navy'
                        maxW={{ base: '100%', md: '30%' }}
                        value={city}
                        onChange={(e) => handleCityChange(e.target.value)}
                    />

                    <FormLabel mt={6} mb={4}>
                        Kjønn:
                    </FormLabel>
                    <RadioGroup
                        value={gender?.toString()}
                        onChange={handleGenderChange}
                        maxW={{ base: '100%', md: '15%' }}
                    >
                        <Stack>
                            <Radio
                                borderColor='NorskTur.Navy'
                                _checked={radioCheckedStyleProps}
                                value='Female'
                            >
                                Kvinne
                            </Radio>
                            <Radio
                                borderColor='NorskTur.Navy'
                                _checked={radioCheckedStyleProps}
                                value='Male'
                            >
                                Mann
                            </Radio>
                        </Stack>
                    </RadioGroup>
                    <HStack
                        spacing={6}
                        display='block'
                        textAlign='end'
                        bottom={8}
                        right={8}
                        mt={{ base: '4rem', md: '0' }}
                    >
                        <Button
                            variant='primary'
                            onClick={() => handleSubmit()}
                            isLoading={loading}
                        >
                            Lagre
                        </Button>
                    </HStack>
                </FormControl>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg='NorskTur.Sand'>
                    <ModalHeader>Info om persondata</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Det er ikke mulig å endre navn på MIN SIDE etter at
                        flydokumenter er utstedt. Dersom det er nødvendig med
                        endringer etter dette, ta kontakt på telefon 38 12 03
                        20.
                        <br /> <br />
                        Obs! Husk at navnet som er registrert må være likt
                        navnet slik det står i passet.
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='primary' mr={3} onClick={onClose}>
                            Lukk
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}
