import { StandardSpinner } from 'common/spinner/standarsSpinner';
import React from 'react';

export function Redirect(props: { to: string }) {
    const { to } = props;

    React.useEffect(() => {
        window.location.href = to;
    }, [to]);

    return <StandardSpinner title=' Redirecting...' />;
}

export default Redirect;
