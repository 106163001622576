import React from 'react';
import '@fontsource/montserrat/';
import { Route, Routes } from 'react-router-dom';
import Redirect from 'common/containers/redirect';
import { returnUrl } from 'common/auth/auth.service';
import { env } from 'env';
import PaymentContainer from 'common/invoice/paymentContainer';
import ConfirmPayment from 'common/invoice/ConfirmPayment';
import ListsContainer from 'common/reports/listsContainer';
import SMSContainer from 'common/containers/smsContainer';
import UserRestrictedComponent from 'common/auth/restrictedComponent';
import Root from './common/layout/rootComponent';
import Login from './common/auth/containers/login';
import NextTrip from './common/containers/nextTrip';
import DailyScheduleContainer from './common/schedule/dailySchedule';
import PlannedTripsContainer from './common/containers/plannedTrips';
import ProfileContainer from './common/profile/profileContainer';
import InvoiceContainer from './common/invoice/invoiceContainer';
import FlightInfoContainer from './common/flightInfo/flightInfoContainer';
import OptionalAdditionsContainer from './common/additions/optionalAdditionsContainer';
import SubNav from './common/nav/subnav';
import HotelContainer from './common/hotel/hotelContainer';
import Logout from './common/auth/containers/logout';

function App() {
    return (
        <Routes>
            <Route path='login/*' element={<Login />} />
            <Route path='logout' element={<Logout />} />
            <Route element={<Root />}>
                <Route
                    index
                    element={
                        <div>
                            <SubNav defaultActiveKey={0} />
                            <NextTrip />
                        </div>
                    }
                />
                <Route
                    path='/planlagte-turer'
                    element={
                        <div>
                            <SubNav defaultActiveKey={1} />
                            <PlannedTripsContainer />
                        </div>
                    }
                />
                <Route
                    path='/dagsprogram'
                    element={
                        <div>
                            <SubNav defaultActiveKey={0} />
                            <DailyScheduleContainer />
                        </div>
                    }
                />
                <Route path='/profil' element={<ProfileContainer />} />
                <Route path='/faktura' element={<InvoiceContainer />} />
                <Route path='/betaling' element={<PaymentContainer />} />
                <Route path='/betaling/confirm' element={<ConfirmPayment />} />
                <Route path='/hotell' element={<HotelContainer />} />
                <Route
                    path='/lister'
                    element={
                        <UserRestrictedComponent
                            allowed={[
                                'TourLeader',
                                'TripAdmin',
                                'TourleaderCustomer',
                            ]}
                        >
                            <ListsContainer />
                        </UserRestrictedComponent>
                    }
                />
                <Route
                    path='/sms'
                    element={
                        <UserRestrictedComponent
                            allowed={['TourLeader', 'TripAdmin']}
                        >
                            <SMSContainer />
                        </UserRestrictedComponent>
                    }
                />

                <Route
                    path='/flyinformasjon'
                    element={<FlightInfoContainer />}
                />
                <Route
                    path='/aktiviteter'
                    element={<OptionalAdditionsContainer />}
                />
            </Route>
            <Route
                path='/registrering'
                element={
                    <Redirect
                        to={`${
                            env.REACT_APP_OIDC_AUTHORITY
                        }/Account/Registration?returnUrl=${returnUrl()}`}
                    />
                }
            />
        </Routes>
    );
}

export default App;
