import React from 'react';
import { Icon } from '@chakra-ui/react';

export function FlightIcon(props: any) {
    return (
        <Icon viewBox='0 0 24 24' {...props}>
            <svg
                width='22'
                height='18'
                viewBox='0 0 22 18'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M1.5 15.9999H20.5V17.9999H1.5V15.9999ZM21.07 6.63995C20.86 5.83995 20.03 5.35995 19.23 5.57995L13.92 6.99995L7.02 0.569946L5.09 1.07995L9.23 8.24995L4.26 9.57995L2.29 8.03995L0.84 8.42995L2.66 11.5899L3.43 12.9199L5.03 12.4899L10.34 11.0699L14.69 9.90995L20 8.48995C20.81 8.25995 21.28 7.43995 21.07 6.63995Z'
                    fill='currentColor'
                />
            </svg>
        </Icon>
    );
}
