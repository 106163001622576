import React from 'react';
import {
    FormControl,
    Heading,
    FormLabel,
    Input,
    HStack,
    Button,
    Box,
} from '@chakra-ui/react';

type PropTypes = {
    onSubmit: ({
        newPassword,
        oldPassword,
    }: {
        newPassword: string;
        oldPassword: string;
    }) => void;
    loading: boolean;
};

export function PasswordFormCard(props: PropTypes) {
    const [oldPassword, setOldPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');

    const { onSubmit, loading } = props;

    const handleSubmit = () => {
        onSubmit({
            newPassword,
            oldPassword,
        });
    };

    return (
        <Box
            padding={4}
            mb={4}
            rounded='lg'
            border='2px solid'
            borderColor='NorskTur.Sand'
        >
            <FormControl>
                <Heading size='sm' mb={2} fontWeight='600'>
                    Passord
                </Heading>

                <FormLabel>Bekreft gammelt passord:</FormLabel>
                <Input
                    id='old_password'
                    type='password'
                    borderColor='NorskTur.Navy'
                    maxW={{ base: '100%', md: '30%' }}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />

                <FormLabel mt={4}>Nytt passord:</FormLabel>
                <Input
                    id='new_password'
                    borderColor='NorskTur.Navy'
                    type='password'
                    maxW={{ base: '100%', md: '30%' }}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <HStack
                    spacing={6}
                    display='block'
                    textAlign='end'
                    bottom={8}
                    right={8}
                    mt={{ base: '4rem', md: '0' }}
                >
                    <Button
                        variant='primary'
                        type='submit'
                        onClick={handleSubmit}
                        isLoading={loading}
                    >
                        Lagre
                    </Button>
                </HStack>
            </FormControl>
        </Box>
    );
}
