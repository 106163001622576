import { Spinner } from '@chakra-ui/react';
import React from 'react';

type SpinnerProps = {
    title?: string;
};

export function StandardSpinner(props: SpinnerProps) {
    const { title } = props;
    return (
        <div>
            <Spinner ml={title ? 4 : 0} mr={4} label={title} />
            {title}
        </div>
    );
}
