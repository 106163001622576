import Redirect from 'common/containers/redirect';
import React from 'react';
import { useAuthContext } from '../../context/authContext';
import { StandardSpinner } from '../../spinner/standarsSpinner';
import { completeLogout, logout } from '../auth.service';

export default function Logout() {
    const auth = useAuthContext();

    if (window.location.pathname === '/logout/response') {
        completeLogout();
    } else {
        logout();
    }

    if (auth && auth.user) {
        return <Redirect to='/' />;
    }
    return <StandardSpinner title='Logger deg ut' />;
}
