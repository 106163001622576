import React from 'react';
import {
    Container,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from '@chakra-ui/react';
import { IContact } from 'common/interfaces/contact';
import { useSearchParams } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';
import { useAuthContext } from '../context/authContext';
import { ReturnLink } from '../nav/returnLink';
import { StandardSpinner } from '../spinner/standarsSpinner';
import { formatSingleDate } from '../utilities/parser';
import OptionalAdditionsHeader from './additionsHeader';
import ContactBookedAdditions from './contactBookedAdditionsContainer';
import {
    useBookedAdditions,
    useContactsByBookingRef,
    useTrip,
    useTripById,
} from '../../api/hooks/ApiHooks';

export default function OptionalAdditionsContainer() {
    const auth = useAuthContext();
    const [searchParams] = useSearchParams();

    const { data: trip } = useTrip(auth, searchParams.get('tripNumber'));
    const [key, setKey] = React.useState(0);
    const [activeContactId, setactiveContactId] = React.useState('');

    const { data: crmTrip, isFetching: isTripFetching } = useTripById(
        auth,
        trip?.tripId
    );

    const {
        data: bookedAdditions,
        isFetching: isBookedAdditionsFetching,
        isIdle: isBookedAdditionsIdle,
    } = useBookedAdditions(auth, trip?.bookingReference);

    const { data: contacts } = useContactsByBookingRef(
        auth,
        trip?.bookingReference
    );

    React.useEffect(() => {
        if (contacts && contacts.length > 0 && contacts[0].contactId) {
            setactiveContactId(contacts[0].contactId);
        }
    }, [contacts]);

    const totalAdditions = crmTrip?.optionalAdditions || [];

    const isLoading =
        isTripFetching || isBookedAdditionsFetching || isBookedAdditionsIdle;

    return (
        <Container maxW='container.xl'>
            {isLoading ? (
                <StandardSpinner title='Henter aktiviteter' />
            ) : (
                <div>
                    <ReturnLink />
                    <Container maxW='container.md'>
                        <OptionalAdditionsHeader
                            tripName={crmTrip?.tripName || ''}
                            tripDate={
                                crmTrip &&
                                formatSingleDate(crmTrip.departureDate, false)
                            }
                        />
                        <Text mb={8}>
                            {HTMLReactParser(
                                crmTrip?.optionalAdditionDescription || ''
                            )}
                        </Text>
                        <Tabs variant='enclosed'>
                            <TabList borderColor='NorskTur.Navy'>
                                {contacts &&
                                    contacts?.map(
                                        (contact: IContact, index: number) => (
                                            <Tab
                                                bg={
                                                    key === index
                                                        ? 'NorskTur.Navy'
                                                        : 'white'
                                                }
                                                color={
                                                    key === index
                                                        ? 'white'
                                                        : 'NorskTur.Navy'
                                                }
                                                key={contact.contactId}
                                                borderBottomColor='NorskTur.Navy'
                                                _selected={{ color: 'white' }}
                                                onClick={() => {
                                                    setactiveContactId(
                                                        contact.contactId || ''
                                                    );
                                                    setKey(index);
                                                }}
                                            >
                                                {`${contact.firstName} ${contact.lastName}`}
                                            </Tab>
                                        )
                                    )}
                            </TabList>
                            <TabPanels>
                                {contacts?.map((contact) =>
                                    totalAdditions.map(
                                        (addition, index: number) => (
                                            <TabPanel
                                                key={contact.contactId}
                                                pl={0}
                                            >
                                                {activeContactId && (
                                                    <ContactBookedAdditions
                                                        contactId={
                                                            activeContactId
                                                        }
                                                        contactBookedAdditions={
                                                            bookedAdditions &&
                                                            bookedAdditions[
                                                                index
                                                            ]
                                                        }
                                                        availableAdditions={
                                                            crmTrip?.optionalAdditions ||
                                                            []
                                                        }
                                                        bookingReference={
                                                            trip?.bookingReference ||
                                                            ''
                                                        }
                                                    />
                                                )}
                                            </TabPanel>
                                        )
                                    )
                                )}
                            </TabPanels>
                        </Tabs>
                    </Container>
                </div>
            )}
        </Container>
    );
}
