import axios from 'axios';
import { env } from 'env';

export type WpError = {
    code: string;
    data: { status: string; trip: string };
    message: string;
};
export const isWpError = (err: unknown) => {
    return (
        (err as WpError)?.code !== undefined &&
        typeof (err as WpError).code === 'string'
    );
};

export const wordpressClient = axios.create({
    baseURL: env.REACT_APP_CONTENT_API,
    headers: {
        'Content-type': 'application/json',
    },
});

export const crmApiClient = (token: string) =>
    axios.create({
        baseURL: env.REACT_APP_CRM_API,
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });

export function generateWPQueryUrl(crmTripNumber: string): string {
    return `${env.REACT_APP_CONTENT_API}trips/v2/meta_query?meta_query[0][key]=_napex_tripNumber&meta_query[0][value]=${crmTripNumber}&meta_query[0][compare]==`;
}

export const downloadClient = (token: string) =>
    axios.create({
        baseURL: env.REACT_APP_CRM_API,
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/pdf',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });

export const smsClient = (token: string) =>
    axios.create({
        baseURL: env.REACT_APP_SMS_API,
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });
