import React from 'react';
import { Box, Button, Container, Flex, HStack, Select } from '@chakra-ui/react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';

type SubNavLink = {
    name: string;
    path: string;
};

export default function SubNav(props: { defaultActiveKey: number }) {
    const { defaultActiveKey } = props;
    const [key, setKey] = React.useState(defaultActiveKey);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const Links: SubNavLink[] = React.useMemo(
        () => [
            {
                name: 'Neste tur',
                path: '/',
            },
            {
                name: 'Planlagte turer',
                path: '/planlagte-turer',
            },
            {
                name: 'Rediger profil',
                path: '/profil',
            },
        ],
        []
    );

    React.useEffect(() => {
        if (pathname === '/') {
            setKey(0);
        }
        if (pathname === '/planlagte-turer') {
            setKey(1);
        }
        if (pathname === '/profil') {
            setKey(2);
        }
    }, [Links, pathname]);

    return (
        <Box bg='NorskTur.Navy' mb={6}>
            <Container maxW='container.xl'>
                <Select
                    border='0px'
                    borderColor='NorskTur.Navy'
                    onChange={(e) => navigate(e.target.value)}
                    display={{ base: 'block', sm: 'none' }}
                    color='NorskTur.Sand'
                    defaultValue='/'
                    backgroundColor='NorskTur.Navy'
                >
                    {Links.map((link) => (
                        <option
                            key={link.name}
                            value={link.path}
                            style={{ backgroundColor: '#25384A' }}
                        >
                            {link.name}
                        </option>
                    ))}
                </Select>
                <Flex
                    h={12}
                    alignItems='center'
                    justifyContent='space-between'
                    display={{ base: 'none', sm: 'block' }}
                >
                    <HStack
                        as='nav'
                        height='100%'
                        spacing={{ base: 4, sm: 10, md: 12 }}
                    >
                        {Links.map((link: SubNavLink, index: number) => (
                            <Button
                                as={RouterLink}
                                to={link.path}
                                variant='link'
                                fontWeight={400}
                                height='100%'
                                py={2.5}
                                key={link.name}
                                borderRadius={0}
                                borderBottom='6px solid'
                                color={
                                    key === index ? 'NorskTur.Sand' : 'white'
                                }
                                borderColor={
                                    key === index
                                        ? 'NorskTur.Sand'
                                        : 'NorskTur.Navy'
                                }
                                _hover={{
                                    color: 'NorskTur.Sand',
                                }}
                                onClick={() => {
                                    setKey(index);
                                }}
                            >
                                {link.name}
                            </Button>
                        ))}
                    </HStack>
                </Flex>
            </Container>
        </Box>
    );
}
