import React from 'react';
import { Box, HStack, Heading, Button, Text } from '@chakra-ui/react';
import moment from 'moment';
import { ITripOptionalAddition } from '../interfaces/addition';
import { StandardSpinner } from '../spinner/standarsSpinner';

import { getDateFromString } from '../utilities/parser';

type AdditionCardProps = {
    addition: ITripOptionalAddition;
    booked: boolean;
    onToggleAddition: (
        addition: ITripOptionalAddition,
        callback: () => void,
        onError: () => void
    ) => void;
};

export default function AdditionCard(props: AdditionCardProps) {
    const { addition, booked, onToggleAddition } = props;

    const isPastDeadline = moment(addition.deadlineDate).isBefore(moment());
    const [loading, setLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const isBooked = booked && !isError;

    const handleClick = () => {
        if (!isBooked) {
            setLoading(true);
            onToggleAddition(
                addition,
                () => setLoading(false),
                () => setIsError(true)
            );
        }
    };

    // const onSuccess = () => {
    //     setLoading(true);
    //     onToggleAddition(
    //         addition,
    //         () => setLoading(false),
    //         () => setIsError(true)
    //     );
    // };

    return (
        <Box
            padding={8}
            rounded='lg'
            border='2px solid'
            borderColor={isBooked ? 'none' : 'NorskTur.Sand'}
            color={isBooked ? 'white' : 'NorskTur.Navy'}
            backgroundColor={isBooked ? 'NorskTur.Navy' : 'white'}
        >
            <Heading size='sm' mb={4}>
                {addition.description}
            </Heading>

            {addition.priceIncludingVat && addition.priceIncludingVat !== 0 ? (
                <Text fontWeight='bold'>
                    Pris: {addition.priceIncludingVat} kr
                </Text>
            ) : (
                ''
            )}
            {addition.deadlineDate && (
                <Text fontWeight='bold'>
                    Frist:{' '}
                    {`${getDateFromString(
                        addition.deadlineDate
                    ).getDate()}. ${getDateFromString(
                        addition.deadlineDate
                    ).toLocaleDateString('no', { month: 'long' })}`}
                </Text>
            )}
            <HStack
                spacing={6}
                display='block'
                textAlign='end'
                bottom={8}
                right={8}
                mt={8}
            >
                {loading && <StandardSpinner title='' />}
                {!loading && !isBooked && (
                    <Button
                        variant={isBooked ? 'inversed' : 'primary'}
                        isDisabled={isPastDeadline || isBooked}
                        _hover={
                            isPastDeadline || isBooked
                                ? { textDecoration: 'none' }
                                : { textDecoration: 'underline' }
                        }
                        onClick={handleClick}
                    >
                        Meld deg på
                    </Button>
                )}
            </HStack>
            {/* <PopupAlert
                header='Meld deg av?'
                text={`Er du sikker på at du vil melde deg av ${addition.description}?`}
                secondaryButtonText='Nei, avbryt'
                primaryButtonText='Meld meg av'
                isOpen={isOpen}
                onClose={onClose}
                onSuccess={onSuccess}
            /> */}
        </Box>
    );
}
