import React from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Container,
    Heading,
} from '@chakra-ui/react';
import { ReturnLink } from 'common/nav/returnLink';
import {
    useAdditionReport,
    useAuthGuard,
    useContact,
    useFlightReport,
    useRoomListReport,
    useTravellerReport,
    useTrip,
} from 'api/hooks/ApiHooks';
import { useAuthContext } from 'common/context/authContext';
import { useSearchParams } from 'react-router-dom';
import { StandardSpinner } from '../spinner/standarsSpinner';
import TravellerReportTable from './travellerReportTable';
import RoomReportTable from './roomReportTable';
import AdditionReportTable from './additionReportTable';
import FlightReportTable from './flightReportTable';
import RemarksTable from './remarksReportTable';
import TravellerReportMobile from './mobile/travellerReportMobile';
import MobileSplitDisplay from './mobile/mobileSplitDisplay';
import RoomReportMobile from './mobile/roomReportMobile';
import RemarksReportMobile from './mobile/remarksReportMobile';
import AdditionReportMobile from './mobile/additionReportMobile';
import FlightReportMobile from './mobile/flightReportMobile';

export default function ListsContainer() {
    const auth = useAuthContext();
    const [searchParams] = useSearchParams();

    const { data: crmTrip, isFetching } = useTrip(
        auth,
        searchParams.get('tripNumber')
    );

    const { data: contact, isFetching: isFetchingContact } = useContact(auth);

    const travellerReport = useAuthGuard(
        useTravellerReport(auth, crmTrip?.tripId),
        ['TourLeader', 'TripAdmin', 'TourleaderCustomer'],
        contact?.identityType
    );

    const roomListReport = useAuthGuard(
        useRoomListReport(auth, crmTrip?.tripId),
        ['TourLeader', 'TripAdmin', 'TourleaderCustomer'],
        contact?.identityType
    );

    const additionReport = useAuthGuard(
        useAdditionReport(auth, crmTrip?.tripId),
        ['TourLeader', 'TripAdmin', 'TourleaderCustomer'],
        contact?.identityType
    );

    const flightReport = useAuthGuard(
        useFlightReport(auth, crmTrip?.tripId),
        ['TourLeader', 'TripAdmin', 'TourleaderCustomer'],
        contact?.identityType
    );

    return (
        <Container maxW='container.xl' px={{ base: 0, md: 4 }}>
            <Box px={{ base: 4, md: 0 }}>
                <ReturnLink />
            </Box>
            <Container maxW='container.2xl' px={{ base: 0, md: 4 }}>
                <Heading size='lg' my={8} px={{ base: 4, md: 0 }}>
                    Lister
                </Heading>
                {isFetching || isFetchingContact ? (
                    <Box px={{ base: 4, md: 0 }}>
                        <StandardSpinner title='Henter info om tur...' />
                    </Box>
                ) : (
                    <div>
                        <Heading size='md' mb={6} px={{ base: 4, lg: 0 }}>
                            {crmTrip?.tripName}
                        </Heading>
                        <Accordion defaultIndex={[0]} allowMultiple>
                            {/* Not ideal, but since all tables are different, we need a different component per accordion */}
                            <AccordionItem>
                                <AccordionButton
                                    height={12}
                                    fontWeight='bold'
                                    _expanded={{
                                        bg: 'NorskTur.Navy',
                                        color: 'white',
                                    }}
                                >
                                    <Box as='span' flex='1' textAlign='left'>
                                        Deltakere{' '}
                                        {travellerReport?.data?.length
                                            ? `- Totalt påmeldte: ${travellerReport.data.length}`
                                            : ''}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel py={0} pb={{ base: 0, lg: 4 }}>
                                    {travellerReport?.isFetching ? (
                                        <Box py={4}>
                                            <StandardSpinner title='Henter...' />
                                        </Box>
                                    ) : (
                                        <MobileSplitDisplay
                                            breakpoint='lg'
                                            baseElement={
                                                <TravellerReportTable
                                                    tripId={
                                                        crmTrip?.tripId || ''
                                                    }
                                                    listData={
                                                        travellerReport?.data
                                                    }
                                                />
                                            }
                                            mobileElement={
                                                <TravellerReportMobile
                                                    listData={
                                                        travellerReport?.data
                                                    }
                                                />
                                            }
                                        />
                                    )}
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionButton
                                    height={12}
                                    fontWeight='bold'
                                    _expanded={{
                                        bg: 'NorskTur.Navy',
                                        color: 'white',
                                    }}
                                >
                                    <Box as='span' flex='1' textAlign='left'>
                                        Romliste
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel py={0} pb={{ base: 0, lg: 4 }}>
                                    {roomListReport?.isFetching ? (
                                        <StandardSpinner title='Henter...' />
                                    ) : (
                                        <MobileSplitDisplay
                                            breakpoint='lg'
                                            baseElement={
                                                <RoomReportTable
                                                    tripId={
                                                        crmTrip?.tripId || ''
                                                    }
                                                    listData={
                                                        roomListReport?.data
                                                    }
                                                />
                                            }
                                            mobileElement={
                                                <RoomReportMobile
                                                    listData={
                                                        roomListReport?.data
                                                    }
                                                />
                                            }
                                        />
                                    )}
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionButton
                                    height={12}
                                    fontWeight='bold'
                                    _expanded={{
                                        bg: 'NorskTur.Navy',
                                        color: 'white',
                                    }}
                                >
                                    <Box as='span' flex='1' textAlign='left'>
                                        Merknader
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel py={0} pb={{ base: 0, lg: 4 }}>
                                    {roomListReport?.isFetching ? (
                                        <StandardSpinner title='Henter...' />
                                    ) : (
                                        <MobileSplitDisplay
                                            breakpoint='lg'
                                            baseElement={
                                                <RemarksTable
                                                    tripId={
                                                        crmTrip?.tripId || ''
                                                    }
                                                    listData={
                                                        roomListReport?.data
                                                    }
                                                />
                                            }
                                            mobileElement={
                                                <RemarksReportMobile
                                                    listData={
                                                        roomListReport?.data
                                                    }
                                                />
                                            }
                                        />
                                    )}
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionButton
                                    height={12}
                                    fontWeight='bold'
                                    _expanded={{
                                        bg: 'NorskTur.Navy',
                                        color: 'white',
                                    }}
                                >
                                    <Box as='span' flex='1' textAlign='left'>
                                        Aktiviteter
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel py={0} pb={{ base: 0, lg: 4 }}>
                                    {additionReport?.isFetching ? (
                                        <StandardSpinner title='Henter...' />
                                    ) : (
                                        <MobileSplitDisplay
                                            breakpoint='lg'
                                            baseElement={
                                                <AdditionReportTable
                                                    tripId={
                                                        crmTrip?.tripId || ''
                                                    }
                                                    listData={
                                                        additionReport?.data
                                                    }
                                                />
                                            }
                                            mobileElement={
                                                <AdditionReportMobile
                                                    listData={
                                                        additionReport?.data
                                                    }
                                                />
                                            }
                                        />
                                    )}
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionButton
                                    height={12}
                                    fontWeight='bold'
                                    _expanded={{
                                        bg: 'NorskTur.Navy',
                                        color: 'white',
                                    }}
                                >
                                    <Box as='span' flex='1' textAlign='left'>
                                        Fly
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel py={0} pb={{ base: 0, lg: 4 }}>
                                    {roomListReport?.isFetching ? (
                                        <StandardSpinner title='Henter...' />
                                    ) : (
                                        <MobileSplitDisplay
                                            breakpoint='lg'
                                            baseElement={
                                                <FlightReportTable
                                                    tripId={
                                                        crmTrip?.tripId || ''
                                                    }
                                                    listData={
                                                        flightReport?.data
                                                    }
                                                />
                                            }
                                            mobileElement={
                                                <FlightReportMobile
                                                    listData={
                                                        flightReport?.data
                                                    }
                                                />
                                            }
                                        />
                                    )}
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                )}
            </Container>
        </Container>
    );
}
