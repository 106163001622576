import React from 'react';
import { Box, HStack, Heading, Button, GridItem } from '@chakra-ui/react';
import { LinkWithQuery } from 'common/containers/linkWithQuery';

type CardViewProps = {
    path: string;
    secondaryPath?: string;
    icon: JSX.Element;
    title: string;
    primaryBtnText?: string | JSX.Element;
    secondaryBtnText?: string | JSX.Element;
    cardContent: React.ReactNode;
};

export default function CardView(props: CardViewProps) {
    const {
        path,
        secondaryPath,
        icon,
        title,
        primaryBtnText,
        secondaryBtnText,
        cardContent,
    } = props;
    return (
        <GridItem
            bg='NorskTur.GråLys'
            padding={8}
            mb={1}
            rounded='lg'
            position='relative'
            minHeight={{
                base: '14rem',
                sm: '18rem',
                md: '14rem',
            }}
            maxHeight='20rem'
        >
            <HStack mb={4}>
                {icon}
                <Heading size='md'>{title}</Heading>
            </HStack>

            <Box>{cardContent}</Box>

            <HStack
                spacing={4}
                display='block'
                textAlign='end'
                position='absolute'
                bottom={8}
                right={8}
                mt={4}
            >
                {secondaryBtnText ? (
                    <LinkWithQuery to={secondaryPath ?? ''}>
                        <Button
                            color='NorskTur.Navy'
                            textDecoration='underline'
                            _hover={{ textDecoration: 'none' }}
                            variant='link'
                        >
                            {secondaryBtnText}
                        </Button>
                    </LinkWithQuery>
                ) : (
                    ''
                )}
                {primaryBtnText ? (
                    <LinkWithQuery to={path}>
                        <Button variant='primary'>{primaryBtnText}</Button>
                    </LinkWithQuery>
                ) : (
                    ''
                )}
            </HStack>
        </GridItem>
    );
}
