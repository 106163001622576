import { useToast } from '@chakra-ui/react';
import { isWpError, WpError } from 'api/axiosClient';
import { AxiosError } from 'axios';
import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthContextProvier } from './common/context/authContext';

const QueryClientProviderContext: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const toast = useToast();

    const getEndpointType = (url: string): string => {
        if (url.toLowerCase().includes('addition')) return 'aktiviteter';
        if (url.toLowerCase().includes('room')) return 'rom';
        if (url.toLowerCase().includes('contact')) return 'bruker';
        if (url.toLowerCase().includes('document')) return 'dokumenter';
        if (url.toLowerCase().includes('flight')) return 'fly';
        if (url.toLowerCase().includes('invoice')) return 'faktura';
        if (url.toLowerCase().includes('trip')) return 'tur';
        return '';
    };

    const getErrorMessage = React.useMemo(
        () =>
            (error: AxiosError): React.ReactNode => {
                if (error.response?.data) {
                    if (typeof error.response?.data === 'string') {
                        return error.response.data;
                    }
                    if (isWpError(error.response.data)) {
                        const { code, message, data } = error.response
                            .data as WpError;
                        return code === 'no_posts'
                            ? `Fant ikke turen med nummer: ${
                                  data?.trip || 'Manglende turnummer'
                              }`
                            : message;
                    }
                }
                if (error.config.url?.toLowerCase().includes('sms'))
                    return `Fikk ikke sendt ut sms! ${error.message}`;
                if (error.config?.url) {
                    return `Noe gikk galt da vi prøvde å hente informasjon om ${getEndpointType(
                        error.config.url
                    )}`;
                }
                return error.message;
            },
        []
    );

    const queryClient = React.useMemo(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnMount: false,
                        refetchOnWindowFocus: false,
                        retryOnMount: false,
                        retry: false,
                        staleTime: 10 * (60 * 1000), // 10 mins
                        cacheTime: 15 * (60 * 1000), // 15 mins
                        keepPreviousData: true,
                        onError(err) {
                            toast({
                                title: 'Oops noe gikk galt!',
                                description: getErrorMessage(err as AxiosError),
                                status: 'error',
                                isClosable: true,
                                position: 'top',
                            });
                        },
                    },
                    mutations: {
                        onError(err) {
                            toast({
                                title: 'Fikk ikke gjennomført',
                                description: getErrorMessage(err as AxiosError),
                                status: 'error',
                                isClosable: true,
                                position: 'top',
                            });
                        },
                    },
                },
            }),
        [toast, getErrorMessage]
    );

    return (
        <QueryClientProvider client={queryClient}>
            {children}
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
};

export const AppContextProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    return (
        <QueryClientProviderContext>
            <AuthContextProvier>{children}</AuthContextProvier>
        </QueryClientProviderContext>
    );
};
