import { Container } from '@chakra-ui/react';
import React from 'react';
import { Search, useLocation, useNavigate } from 'react-router-dom';
import { StandardSpinner } from '../../spinner/standarsSpinner';
import { useLogin } from '../auth.service';
import isAuthenticated from '../isAuthenticated';

interface LocationState {
    from: {
        pathname: string;
        search: Search;
    };
}

export default function Login() {
    const { user, authError } = useLogin();
    const navigate = useNavigate();
    const location = useLocation();

    const { from } = (location.state as LocationState) || {
        from: { pathname: '/' },
    };

    React.useEffect(() => {
        if (isAuthenticated(user?.access_token, user?.expired)) {
            navigate(`${from.pathname}${from.search || ''}`, { replace: true });
        }
    }, [from, navigate, user?.access_token, user?.expired]);
    return authError ? (
        <Container>{authError}</Container>
    ) : (
        <Container mt={8}>
            <StandardSpinner title='Logger deg inn' />
        </Container>
    );
}
