import React from 'react';
import {
    Button,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { useTrip } from 'api/hooks/ApiHooks';
import { useAuthContext } from 'common/context/authContext';
import { useWpTrip } from 'api/hooks/WordpressHooks';
import HTMLReactParser from 'html-react-parser';
import { StandardSpinner } from 'common/spinner/standarsSpinner';
import { useSearchParams } from 'react-router-dom';

export default function Terms() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [searchParams] = useSearchParams();

    const { data, isFetching } = useWpTrip(
        useTrip(
            useAuthContext(),
            searchParams.get('tripNumber')
        ).data?.tripNumber.toString()
    );
    return (
        <div>
            <Button variant='linkButton' onClick={onOpen} p={0}>
                <Icon mr={2} /> Betingelser
            </Button>

            <Modal isOpen={isOpen} size='xl' onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg='NorskTur.Sand'>
                    <ModalHeader>Betingelser</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {isFetching ? (
                            <StandardSpinner title='Henter betingelser' />
                        ) : (
                            <Text>
                                {HTMLReactParser(data?.terms || '', {
                                    trim: true,
                                })}
                            </Text>
                        )}
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='primary' mr={3} onClick={onClose}>
                            Lukk
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}
