import React from 'react';
import { Box, Container, Heading } from '@chakra-ui/react';
import { useAuthGuard, useContact } from 'api/hooks/ApiHooks';
import { StandardSpinner } from 'common/spinner/standarsSpinner';
import { ReturnLink } from 'common/nav/returnLink';
import { useAuthContext } from '../context/authContext';

export const UserRestrictedComponent: React.FC<{
    allowed: string[];
    children: React.ReactNode;
}> = ({ children, allowed }): React.ReactElement => {
    const { data, isFetching } = useContact(useAuthContext());
    const allowedUser = useAuthGuard(true, allowed, data?.identityType);

    if (isFetching) {
        return (
            <Container maxW='container.xl'>
                <StandardSpinner />
            </Container>
        );
    }

    return allowedUser ? (
        <div>{children}</div>
    ) : (
        <Container maxW='container.xl'>
            <Box px={{ base: 4, md: 0 }}>
                <ReturnLink to='/' />
                <Heading size='lg'>
                    Du har ikke tilgang til å se denne siden
                </Heading>
            </Box>
        </Container>
    );
};

export default UserRestrictedComponent;
