import React from 'react';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useDisclosure,
    Link,
    Portal,
} from '@chakra-ui/react';

type NavElementProps = {
    name: string;
    path?: string;
    sub?: {
        name: string;
        path: string;
    }[];
};

export function NavElement(props: NavElementProps) {
    const { name, path, sub } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Menu isOpen={isOpen}>
            <Link
                _hover={{
                    color: 'NorskTur.Sand',
                }}
                href={path ? `https://norsktur.no${path}` : undefined}
            >
                <MenuButton
                    onMouseEnter={onOpen}
                    onMouseLeave={onClose}
                    h='5.45rem'
                    position='relative'
                    _before={{
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        bottom: '-1px',
                        height: '3px',
                        bg: 'NorskTur.Sand',
                        transition: '.3s ease-in-out',
                        transitionProperty:
                            'background-color,border-color,box-shadow,height,left,right,opacity',
                        left: '0px',
                        right: isOpen ? '0px' : '102%',
                    }}
                >
                    {name}
                </MenuButton>
            </Link>

            {sub && (
                <Portal>
                    <MenuList
                        top='-12px'
                        zIndex={10}
                        borderRadius='0'
                        onMouseEnter={onOpen}
                        onMouseLeave={onClose}
                    >
                        {sub.map((link) => (
                            <MenuItem
                                key={link.name}
                                p={4}
                                _hover={{
                                    bg: 'white',
                                    color: 'NorskTur.Sand',
                                }}
                            >
                                <Link
                                    href={`https://norsktur.no${link.path}`}
                                    _hover={{
                                        color: 'NorskTur.Sand',
                                    }}
                                >
                                    {link.name}
                                </Link>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Portal>
            )}
        </Menu>
    );
}
