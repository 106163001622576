import React from 'react';
import { Box, Container, Heading, Link, Text } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../context/authContext';
import { ReturnLink } from '../nav/returnLink';
import { StandardSpinner } from '../spinner/standarsSpinner';
import { useWpTrip } from '../../api/hooks/WordpressHooks';
import { useBookedRooms, useContact, useTrip } from '../../api/hooks/ApiHooks';

export default function HotelContainer() {
    const auth = useAuthContext();
    const [searchParams] = useSearchParams();

    const { data: crmTrip } = useTrip(auth, searchParams.get('tripNumber'));
    const { data: contactData } = useContact(auth);

    const {
        data: roomData,
        isFetching: isBookedRoomInfoFetching,
        isIdle: isBookedRoomInfoIdle,
    } = useBookedRooms(auth, crmTrip?.bookingReference);

    const { data: wpTripInfo, isFetching: isWpTripFetching } = useWpTrip(
        crmTrip?.tripNumber.toString()
    );

    const isLoading =
        isBookedRoomInfoFetching || isBookedRoomInfoIdle || isWpTripFetching;

    const roomBuddies = () => {
        if (contactData && roomData?.contacts) {
            return roomData.contacts.filter(
                (contact) => contact?.contactId !== contactData.contactId
            );
        }
        return undefined;
    };

    const createRoomText = (hotelName: string): string => {
        const buddies = roomBuddies();
        if (roomData) {
            if (roomData.roomCategory?.roomCategory?.toLowerCase() === 'sgl') {
                return `Du har ${roomData.roomCategory.roomCategoryName} på ${hotelName}`;
            }
            return `Du har ${
                roomData.roomCategory?.roomCategoryName
            } på ${hotelName} ${
                buddies && buddies.length > 0 && 'sammen med '
            }${
                buddies &&
                buddies
                    .map(
                        (contact) =>
                            `${contact?.firstName} ${contact?.lastName}`
                    )
                    .join(' og ')
            }`;
        }
        return '';
    };
    return (
        <Container maxW='container.xl'>
            {isLoading ? (
                <StandardSpinner title='Henter rom og hotell' />
            ) : (
                <div>
                    <ReturnLink />
                    <Container maxW='container.md'>
                        <Heading size='lg' my={4}>
                            Rom og hotell
                        </Heading>

                        {wpTripInfo?.hotel ? (
                            wpTripInfo.hotel.map((hotelInfo) => (
                                <Box key={hotelInfo.hotel_name}>
                                    <Heading size='sm' mb={4}>
                                        {hotelInfo.hotel_name}
                                    </Heading>
                                    {roomData && (
                                        <Text>
                                            {createRoomText(
                                                hotelInfo.hotel_name
                                            )}
                                        </Text>
                                    )}
                                    <Text my={4}>{hotelInfo.description}</Text>

                                    <Text mb={4}>
                                        {hotelInfo.hotel_address}
                                    </Text>

                                    {hotelInfo.hotel_URL && (
                                        <Link
                                            fontWeight='bold'
                                            textDecoration='underline'
                                            _hover={{ textDecoration: 'none ' }}
                                            target='_blank'
                                            isExternal
                                            href={
                                                hotelInfo.hotel_URL.startsWith(
                                                    'https://'
                                                )
                                                    ? hotelInfo.hotel_URL
                                                    : `https://${hotelInfo.hotel_URL}`
                                            }
                                        >
                                            Gå til hotellets nettside
                                        </Link>
                                    )}
                                </Box>
                            ))
                        ) : (
                            <Heading size='md' mt={16}>
                                Ingen hotellinfo tilgjengelig
                            </Heading>
                        )}
                    </Container>
                </div>
            )}
        </Container>
    );
}
