import React from 'react';
import { Icon } from '@chakra-ui/react';

export function HotelIcon(props: any) {
    return (
        <Icon viewBox='0 0 24 24' {...props}>
            <svg
                width='22'
                height='18'
                viewBox='0 0 22 18'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M10.25 6H11.75V5.25C11.75 5.05109 11.829 4.86032 11.9697 4.71967C12.1103 4.57902 12.3011 4.5 12.5 4.5H14.75C14.9489 4.5 15.1397 4.57902 15.2803 4.71967C15.421 4.86032 15.5 5.05109 15.5 5.25V6H18.5V3C18.5 2.20435 18.1839 1.44129 17.6213 0.87868C17.0587 0.31607 16.2956 0 15.5 0H6.5C5.70435 0 4.94129 0.31607 4.37868 0.87868C3.81607 1.44129 3.5 2.20435 3.5 3V6H6.5V5.25C6.5 5.05109 6.57902 4.86032 6.71967 4.71967C6.86032 4.57902 7.05109 4.5 7.25 4.5H9.5C9.69891 4.5 9.88968 4.57902 10.0303 4.71967C10.171 4.86032 10.25 5.05109 10.25 5.25V6Z'
                    fill='currentColor'
                />
                <path
                    d='M4.25 7.5C3.25544 7.5 2.30161 7.89509 1.59835 8.59835C0.895088 9.30161 0.5 10.2554 0.5 11.25V17.25C0.5 17.4489 0.579018 17.6397 0.71967 17.7803C0.860322 17.921 1.05109 18 1.25 18C1.44891 18 1.63968 17.921 1.78033 17.7803C1.92098 17.6397 2 17.4489 2 17.25V13.5H20V17.25C20 17.4489 20.079 17.6397 20.2197 17.7803C20.3603 17.921 20.5511 18 20.75 18C20.9489 18 21.1397 17.921 21.2803 17.7803C21.421 17.6397 21.5 17.4489 21.5 17.25V11.25C21.5 10.2554 21.1049 9.30161 20.4016 8.59835C19.6984 7.89509 18.7446 7.5 17.75 7.5H4.25Z'
                    fill='currentColor'
                />
            </svg>
        </Icon>
    );
}
