import React from 'react';
import { Icon } from '@chakra-ui/react';

export function SearchIcon(props: any) {
    return (
        <Icon viewBox='0 0 20 20' {...props}>
            <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
            >
                <circle
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='1.1'
                    cx='9'
                    cy='9'
                    r='7'
                />
                <path
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='1.1'
                    d='M14,14 L18,18 L14,14 Z'
                />
            </svg>
        </Icon>
    );
}
