import { IPaymentRequest } from 'common/interfaces/paymentRequest';
import { env } from 'env';

export const generatePaymentUrl = ({
    tripName,
    kid,
    customerEmail,
    customerFirstName,
    customerLastName,
    customerNumber,
    amount,
    uuid,
}: IPaymentRequest): string => {
    const trimAmount = (a: string) => {
        if (a.includes('.')) {
            return a.replace('.', '');
        }
        return `${a}00`;
    };
    return `${
        env.REACT_APP_PAYMENT_URL
    }/create-payment.php/?kid=${kid}&tripname=${tripName}&amount=${trimAmount(
        amount
    )}&uuid=${uuid}&firstName=${customerFirstName}&lastName=${customerLastName}&customerRef=${customerNumber}&email=${customerEmail}`;
};
