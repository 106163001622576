import React from 'react';
import { Icon } from '@chakra-ui/react';

export function InFlightIcon(props: any) {
    return (
        <Icon viewBox='0 0 24 24' {...props}>
            <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M4.67027 10H9.03957L13.0575 4.25188C13.1111 4.17535 13.1885 4.11175 13.2818 4.06752C13.3751 4.02329 13.4811 3.99999 13.5889 4L16.093 4C16.4994 4 16.7926 4.31781 16.681 4.63719L14.8065 10H18.7389L20.3904 8.2C20.5058 8.07406 20.6874 8 20.8797 8H22.4085C22.8065 8 23.0986 8.30562 23.0019 8.62125L21.7973 12L23.0019 15.3787C23.0986 15.6944 22.8065 16 22.4085 16H20.8797C20.6871 16 20.5058 15.9259 20.3904 15.8L18.7389 14H14.8065L16.681 19.3625C16.7926 19.6819 16.4994 20 16.093 20H13.5889C13.3695 20 13.1669 19.9038 13.0579 19.7481L9.03957 14H4.67027C3.31885 14 1.0002 13.1047 1.0002 12C1.0002 10.8953 3.31885 10 4.67027 10Z'
                    fill='#25384A'
                />
            </svg>
        </Icon>
    );
}
