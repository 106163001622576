import React from 'react';
import {
    Avatar,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    MenuButton,
    useDisclosure,
    VStack,
    UnorderedList,
    ListItem,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import avatar from '../assets/profile-icon.svg';
import { NavLink } from './navLink';
import { useAuthContext } from '../context/authContext';

export function MyPageDrawer() {
    const { user } = useAuthContext();
    const { isOpen, onOpen, onClose } = useDisclosure();

    if (!user) {
        return null;
    }

    return (
        <div>
            <MenuButton
                as={Button}
                variant='link'
                cursor='pointer'
                minW={0}
                onClick={onOpen}
            >
                <VStack color='NorskTur.Navy'>
                    <Avatar size='xs' src={avatar} />
                    <NavLink path='/' name='Min Side' />
                </VStack>
            </MenuButton>
            <Drawer isOpen={isOpen} placement='right' onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />

                    <DrawerBody>
                        <UnorderedList mt={12} listStyleType='none'>
                            <ListItem
                                mt={4}
                                _hover={{ textDecoration: 'underline' }}
                            >
                                <RouterLink to='profil' onClick={onClose}>
                                    Rediger Profil
                                </RouterLink>
                            </ListItem>
                            <ListItem
                                mt={4}
                                _hover={{ textDecoration: 'underline' }}
                            >
                                <RouterLink to='/' onClick={onClose}>
                                    Neste tur
                                </RouterLink>
                            </ListItem>
                            <ListItem
                                mt={4}
                                _hover={{ textDecoration: 'underline' }}
                            >
                                <RouterLink
                                    to='/planlagte-turer'
                                    onClick={onClose}
                                >
                                    Planlagte turer
                                </RouterLink>
                            </ListItem>
                            <ListItem
                                mt={4}
                                textDecoration='underline'
                                _hover={{ textDecoration: 'none' }}
                                fontWeight='bold'
                                onClick={onClose}
                            >
                                <RouterLink to='logout'>Logg ut</RouterLink>
                            </ListItem>
                        </UnorderedList>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </div>
    );
}
