import {
    FormControl,
    Heading,
    FormLabel,
    Input,
    HStack,
    Button,
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Icon,
} from '@chakra-ui/react';
import { Patch, PatchContext } from 'common/context/patchContext';
import { IPassport } from 'common/interfaces/contact';
import React from 'react';
import moment from 'moment';
import { CountryCodeSelector } from '../../utilities/countryCodeSelector';

type PropTypes = {
    onSubmit: (patch: Patch[]) => void;
    passport?: IPassport;
    loading: boolean;
};

export function PassportFormCard(props: PropTypes) {
    const { passport, onSubmit, loading } = props;

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [passportNumber, setPassportNumber] = React.useState(
        passport?.passportNumber || ''
    );
    const [nationality, setNationality] = React.useState(
        passport?.nationality || ''
    );
    const [issuedDate, setIssuedDate] = React.useState(
        passport?.issuedDate
            ? moment.utc(passport?.issuedDate).format('YYYY-MM-DD')
            : undefined
    );
    const [expiryDate, setExpiryDate] = React.useState(
        passport?.expiryDate
            ? moment.utc(passport?.expiryDate).format('YYYY-MM-DD')
            : undefined
    );
    const [issuedLocation, setIssuedLocation] = React.useState(
        passport?.issuedLocation || ''
    );

    const { patch, addPatch } = React.useContext(PatchContext);

    const handleSubmit = () => {
        if (patch) onSubmit(patch);
    };

    const onDeletePassport = () => {
        onSubmit([{ op: 'remove', path: '/passport' }]);
    };

    const handlePassportNumberChange = (value: string) => {
        setPassportNumber(value);
        addPatch('/passport/passportNumber', value);
    };
    const handleNationalityChange = (value: string) => {
        setNationality(value);
        addPatch('/passport/nationality', value);
    };
    const handleIssuedDateChanged = (value: string) => {
        setIssuedDate(new Date(value).toISOString().split('T')[0]);
        addPatch('/passport/issuedDate', new Date(value).toJSON());
    };
    const handleExpiryDateChange = (value: string) => {
        setExpiryDate(new Date(value).toISOString().split('T')[0]);
        addPatch('/passport/expiryDate', new Date(value).toJSON());
    };
    const handleIssuedLocationChanged = (value: string) => {
        setIssuedLocation(value);
        addPatch('/passport/issuedLocation', value);
    };

    return (
        <div>
            <Box
                padding={4}
                mb={4}
                rounded='lg'
                border='2px solid'
                borderColor='NorskTur.Sand'
            >
                <FormControl>
                    <Heading size='sm' mb={2} fontWeight='600'>
                        Passet ditt
                    </Heading>

                    <Button
                        pl='0'
                        variant='linkButton'
                        onClick={onOpen}
                        fontWeight='400'
                    >
                        <Icon mr={2} /> Informasjon om pass
                    </Button>

                    <FormLabel>Passnummer</FormLabel>
                    <Input
                        id='passport_number'
                        borderColor='NorskTur.Navy'
                        maxW={{ base: '100%', md: '30%' }}
                        value={passportNumber}
                        onChange={(e) =>
                            handlePassportNumberChange(e.target.value)
                        }
                    />

                    <FormLabel mt={4}>Nasjonalitet</FormLabel>
                    <CountryCodeSelector
                        borderColor='NorskTur.Navy'
                        maxW={{ base: '100%', md: '30%' }}
                        value={nationality}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                            handleNationalityChange(e.target.value)
                        }
                    />

                    <FormLabel mt={4}>Utstedt dato</FormLabel>
                    <Input
                        id='issued_date'
                        borderColor='NorskTur.Navy'
                        maxW={{ base: '100%', md: '30%' }}
                        type='date'
                        value={issuedDate}
                        onChange={(e) =>
                            handleIssuedDateChanged(e.target.value)
                        }
                    />

                    <FormLabel mt={4}>Utløpsdato</FormLabel>
                    <Input
                        id='expiry_date'
                        borderColor='NorskTur.Navy'
                        type='date'
                        maxW={{ base: '100%', md: '30%' }}
                        value={expiryDate}
                        onChange={(e) => handleExpiryDateChange(e.target.value)}
                    />

                    <FormLabel mt={4}>Utstedt sted</FormLabel>
                    <Input
                        id='issued_location'
                        borderColor='NorskTur.Navy'
                        maxW={{ base: '100%', md: '30%' }}
                        value={issuedLocation}
                        onChange={(e) =>
                            handleIssuedLocationChanged(e.target.value)
                        }
                    />

                    <HStack
                        spacing={6}
                        display='block'
                        textAlign='end'
                        bottom={8}
                        right={8}
                        mt={{ base: '4rem', md: '0' }}
                    >
                        <Button variant='inversed' onClick={onDeletePassport}>
                            Slett passet ditt
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleSubmit}
                            isLoading={loading}
                        >
                            Lagre
                        </Button>
                    </HStack>
                </FormControl>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg='NorskTur.Sand'>
                    <ModalHeader>Info om pass</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Skal du reise til en destinasjon som har visumplikt, vil
                        du motta informasjon om dette. <br />
                        <br />
                        Hvis du ikke er norsk statsborger, er du selv ansvarlig
                        for å innhente informasjon om eventuell visumplikt til
                        destinasjonen og søke om eget visum.
                        <br />
                        <br />
                        Passet må være gyldig i minst 6 måneder etter hjemkomst.
                        <br />
                        <br />
                        Merk at passinformasjon regnes som sensitive
                        personopplysninger og vi sikrer disse spesielt i våre
                        systemer slik at de ikke kommer i uvedkommendes hender.{' '}
                        <br />
                        <br />
                        Passinformasjon vil i tillegg bli automatisk slettet en
                        måned etter siste hjemkomst av sikkerhetsmessige
                        årsaker.
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='primary' mr={3} onClick={onClose}>
                            Lukk
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}
