import moment from 'moment';
/**
 * Converts string to Date
 * @param date datestring
 */
export function getDateFromString(date: string) {
    return moment.utc(date).local().toDate();
}

/**
 * Formats a string date from DDMMYYY OR yyyy-MM-dd'T':HH:mm:ss to dd.mm.yyyy
 * Checks if the datestring containts 6 characters from 0-9(first format) or 4
 * characters from 0-9(second format)
 * @param date datestring with format DDMMYYYY OR yyyy-MM-dd'T':HH:mm:ss
 */
export function formatTripDate(
    departureDateString: string,
    returnDateString: string
) {
    const departureDate = getDateFromString(departureDateString);
    const returnDate = getDateFromString(returnDateString);

    if (
        departureDate.getMonth() === returnDate.getMonth() &&
        departureDate.getFullYear() === returnDate.getFullYear()
    ) {
        // Format pretty date w/ text
        const month = departureDate.toLocaleDateString('no', { month: 'long' });
        return `${departureDate.getDate().toString()}.-${returnDate
            .getDate()
            .toString()}. ${month} ${departureDate.getFullYear()}`;
    }
    const departureDateText = departureDate.toLocaleDateString('no');
    const returnDateText = returnDate.toLocaleDateString('no');

    // Dates are not in same month or year, so we just use numbers
    return `${departureDateText} - ${returnDateText}`;
}

export function formatSingleDate(dateString?: string, includeTime?: boolean) {
    if (!dateString) return undefined;
    const date = getDateFromString(dateString);

    // Format pretty date w/ text
    const month = date.toLocaleDateString('no', { month: 'long' });
    const result = includeTime
        ? `${date
              .getDate()
              .toString()}. ${month} ${date.getFullYear()} kl ${`0${date.getHours()}`.slice(
              -2
          )}:${`0${date.getMinutes()}`.slice(-2)}`
        : `${date.getDate().toString()}. ${month} ${date.getFullYear()}`;

    return result;
}
