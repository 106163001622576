import React from 'react';
import {
    Heading,
    Text,
    Button,
    Icon,
    useDisclosure,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';

type OptionalAdditionsHeaderProps = {
    tripName: string;
    tripDate?: string;
};
export default function OptionalAdditionsHeader(
    props: OptionalAdditionsHeaderProps
) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { tripName, tripDate } = props;

    return (
        <div>
            <Heading size='md' my={2}>
                Aktiviteter
                <Icon w={6} ml={4} onClick={onOpen} />
            </Heading>
            <Text fontWeight='bold'>{tripName}</Text>
            <Text mb={8}>{tripDate}</Text>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg='NorskTur.Sand'>
                    <ModalHeader>Aktiviteter</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Her kan du melde deg på aktiviteter/utflukter tilknyttet
                        reisen din. Disse aktivitetene er frivillige. <br />
                        Vi tar forbehold om tilstrekkelig antall påmeldte for at
                        disse kan gjennomføres. Påmelding anses som bindende og
                        vil bli fakturert. Du kan avbestille aktiviteter
                        kostnadsfritt frem mot 30 dager før avreise. <br />
                        For spørsmål, ta kontakt på telefon 38 12 03 35
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='primary' mr={3} onClick={onClose}>
                            Lukk
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}
