import { Box } from '@chakra-ui/react';
import { useAuthContext } from 'common/context/authContext';
import { useUpdatePassword, useUpdateUserInfo } from 'api/hooks/ApiHooks';
import React from 'react';
import { StandardSpinner } from 'common/spinner/standarsSpinner';
import { PatchContextProvider } from 'common/context/patchContext';
import { IContact } from '../interfaces/contact';
import { DeleteUserInfo } from './deleteUserInfo';
import { DownloadUserInfo } from './downloadUserInfo';
import { AllergiesFormCard } from './editProfileCards/allergies';
import { EmailFormCard } from './editProfileCards/email';
import { PassportFormCard } from './editProfileCards/passport';
import { PasswordFormCard } from './editProfileCards/password';
import { PersonaliaFormCard } from './editProfileCards/personalia';

type EditProfileInfoProps = {
    contact: IContact;
};

export default function EditProfileInfo(props: EditProfileInfoProps) {
    const { contact } = props;
    const auth = useAuthContext();
    const [loading, setLoading] = React.useState(false);

    const { mutate: updateUser, isLoading: isUpdating } = useUpdateUserInfo(
        auth,
        contact.contactId || '',
        { onSettled: () => setLoading(false) }
    );

    const { mutate: updatePassword } = useUpdatePassword(
        auth,
        contact.email || '',
        {
            onSettled: () => setLoading(false),
        }
    );

    return (
        <Box>
            {isUpdating ? (
                <StandardSpinner title='Oppdaterer brukerinfo' />
            ) : (
                <PatchContextProvider>
                    <EmailFormCard
                        email={contact.email || ''}
                        isAcceptNewsletter={contact.isAcceptNewsletter || false}
                        onSubmit={(patch) => {
                            setLoading(true);
                            updateUser(patch);
                        }}
                        loading={loading}
                    />
                    <PasswordFormCard
                        onSubmit={(body) => {
                            setLoading(true);
                            updatePassword(body);
                        }}
                        loading={loading}
                    />
                    <PersonaliaFormCard
                        contact={contact}
                        onSubmit={(patch) => {
                            setLoading(true);
                            updateUser(patch);
                        }}
                        loading={loading}
                    />
                    <PassportFormCard
                        passport={contact.passport}
                        onSubmit={(patch) => {
                            setLoading(true);
                            updateUser(patch);
                        }}
                        loading={loading}
                    />
                    <AllergiesFormCard
                        allergies={contact.allergies || ''}
                        remarks={contact.remarks || ''}
                        onSubmit={(patch) => {
                            setLoading(true);
                            updateUser(patch);
                        }}
                        loading={loading}
                    />
                    <DownloadUserInfo />
                    * Ikke funksjonelt
                    <DeleteUserInfo />* Ikke funksjonelt
                </PatchContextProvider>
            )}
        </Box>
    );
}
