import {
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Text,
    Box,
    Heading,
    Button,
} from '@chakra-ui/react';
import { SortedRoomReportWithTotals } from 'common/interfaces/reports';
import React from 'react';
import { usePagination, useTable } from 'react-table';
import { useReportDownload } from 'api/hooks/ApiHooks';
import { useAuthContext } from 'common/context/authContext';
import Paginator from './paginator';

type Proptypes = {
    tripId: string;
    listData?: SortedRoomReportWithTotals;
};

function RoomReportTable(props: Proptypes) {
    const { listData, tripId } = props;

    const data = React.useMemo(
        () =>
            (listData?.rooms ?? []).flatMap((rooms) => {
                return {
                    name: (rooms?.contacts || []).map((contact, index) => (
                        <Text
                            mt={index + 1 > 1 ? 4 : 0}
                            key={`${contact.firstName}${contact.lastName}`}
                        >
                            {contact.lastName}, {contact.firstName}{' '}
                            {contact.travellerType === 'TourLeader'
                                ? '(RL)'
                                : ''}
                        </Text>
                    )),
                    companyName: (rooms?.contacts || []).map(
                        (contact) => contact.companyName
                    ),
                    room: rooms?.roomCategory ?? '',
                };
            }),
        [listData]
    );

    const totalRooms = React.useMemo(() => {
        let roomsTotal = 0;
        listData?.totals.forEach((total) => {
            roomsTotal += Number(total.noOfRooms);
        });

        return roomsTotal;
    }, [listData?.totals]);

    const totalTravellers = React.useMemo(() => {
        let travellersTotal = 0;
        listData?.totals.forEach((total) => {
            travellersTotal += Number(total.noOfTravellers);
        });

        return travellersTotal;
    }, [listData?.totals]);

    const { startDownload, loading } = useReportDownload(
        useAuthContext(),
        tripId,
        'Romliste med bedrift'
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'Navn',
                accessor: 'name', // accessor is the "key" in the data
                width: 200,
            } as const,
            {
                Header: 'Rom',
                accessor: 'room',
            } as const,
            {
                Header: 'Bedrift',
                accessor: 'companyName',
            } as const,
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination
    );

    return (
        <div>
            <Table variant='striped' {...getTableProps()} mb={4}>
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <Td
                                            {...cell.getCellProps({
                                                style: {
                                                    width: cell.column.width,
                                                    maxWidth:
                                                        cell.column.maxWidth,
                                                    minWidth:
                                                        cell.column.minWidth,
                                                },
                                            })}
                                        >
                                            {cell.render('Cell')}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            <Paginator
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                currentPageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
                numberOfPages={pageCount}
                previousPage={previousPage}
                pageOptions={pageOptions}
                pageSize={pageSize}
                setPageSize={setPageSize}
            />
            <Button
                float='right'
                mx={5}
                onClick={startDownload}
                isLoading={loading}
            >
                Skriv ut
            </Button>
            <Box m={4}>
                <Heading size='md'>Totaler</Heading>
                {listData?.totals?.map((totals) => (
                    <Text key={totals.roomCategory} mt={4}>
                        {totals.roomCategory}: {totals.noOfRooms}
                    </Text>
                ))}
                <Text mt={4} fontWeight='bold'>
                    Antall rom: {totalRooms}
                </Text>
                <Text mt={4} fontWeight='bold'>
                    Antall reisende: {totalTravellers}
                </Text>
            </Box>
        </div>
    );
}

export default RoomReportTable;
