import React from 'react';
import {
    GridItem,
    Heading,
    HStack,
    LayoutProps,
    ListItem,
    UnorderedList,
} from '@chakra-ui/react';
import { IDocument } from 'common/interfaces/document';
import { StandardSpinner } from 'common/spinner/standarsSpinner';
import Terms from './terms';
import { DownloadFileListElement } from './downloadFileContainer';

type PropType = {
    documents?: IDocument[];
    isLoading: boolean;
    display?: LayoutProps['display'];
};

export default function TripDocuments(props: PropType) {
    const { documents, isLoading, display } = props;

    return (
        <GridItem
            padding={8}
            mb={1}
            rounded='lg'
            border='2px solid'
            borderColor='NorskTur.Sand'
            display={display}
        >
            <HStack mb={4}>
                <Heading size='md'>Dokumenter</Heading>
            </HStack>
            {isLoading ? (
                <StandardSpinner title='Henter dokumenter' />
            ) : (
                <UnorderedList margin={0} listStyleType='none'>
                    {documents &&
                        documents.map((document) => (
                            <DownloadFileListElement
                                file={document}
                                key={document.id}
                            />
                        ))}
                    <ListItem mt={4}>
                        <Terms />
                    </ListItem>
                </UnorderedList>
            )}
        </GridItem>
    );
}
