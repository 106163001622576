import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
    fonts: {
        heading: 'Montserrat',
        body: 'Montserrat',
    },
    shadows: {
        outline: {
            color: 'NorskTur.Navy',
        },
    },
    colors: {
        NorskTur: {
            500: '#25384A',
            NavyLys: '#7994AC',
            Navy: '#25384A',
            Peach: '#FCC7A1',
            Varselrød: '#B20B01',
            GråMedium: '#C4C4C4',
            GråMørk: '#6B6B6B',
            GråLys: '#F3F3F3',
            Sand: '#EED2AB',
        },
    },
    components: {
        Button: {
            variants: {
                'primary': {
                    background: 'NorskTur.Navy',
                    color: 'white',
                    _hover: {
                        textDecoration: 'underline',
                    },
                },
                'inversed': {
                    background: 'white',
                    color: 'NorskTur.Navy',
                    _hover: {
                        textDecoration: 'underline',
                    },
                    border: '2px',
                    borderColor: 'NorskTur.Navy',
                },
                'linkButton': {
                    color: 'NorskTur.Navy',
                    textDecoration: 'underline',
                    _hover: {
                        textDecoration: 'none',
                    },
                },
            },
        },
        Divider: {
            baseStyle: {
                borderColor: 'black',
            },
        },
    },
});

export const radioCheckedStyleProps = {
    bg: 'NorskTur.Sand',
    borderColor: 'NorskTur.Navy',
    _before: {
        content: '""',
        display: 'inline-block',
        width: '50%',
        height: '50%',
        borderRadius: '50%',
        background: 'NorskTur.Navy',
    },
};
