import React from 'react';
import {
    Heading,
    FormLabel,
    Input,
    RadioGroup,
    Stack,
    Radio,
    Text,
    Box,
    HStack,
    Button,
} from '@chakra-ui/react';
import { radioCheckedStyleProps } from 'theme';
import { Patch, PatchContext } from 'common/context/patchContext';

type EmailCardProps = {
    email: string;
    isAcceptNewsletter: boolean;
    onSubmit: (patch: Patch[]) => void;
    loading: boolean;
};

export function EmailFormCard(props: EmailCardProps) {
    const { email, isAcceptNewsletter, onSubmit, loading } = props;

    const [emailState, setEmailState] = React.useState(email || '');
    const [newsLetterState, setNewsLetterState] = React.useState(
        isAcceptNewsletter.toString()
    );

    const { patch, addPatch } = React.useContext(PatchContext);

    const handleEmailChange = (value: string) => {
        setEmailState(value);
        addPatch('/email', value);
    };

    const handleNewsletterChange = (value: string) => {
        setNewsLetterState(value);
        addPatch('/isAcceptNewsletter', value);
    };

    const handleSubmit = () => {
        if (patch) onSubmit(patch);
    };

    return (
        <Box
            padding={4}
            mb={4}
            rounded='lg'
            border='2px solid'
            borderColor='NorskTur.Sand'
        >
            <Heading size='sm' mb={2} fontWeight='600'>
                E-post / brukernavn
            </Heading>

            <FormLabel>Du er registrert på</FormLabel>
            <Input
                id='email'
                value={emailState}
                borderColor='NorskTur.Navy'
                maxW={{ base: '100%', md: '30%' }}
                disabled
                onChange={(e) => handleEmailChange(e.target.value)}
            />
            <Text mb={4}>
                Hvis du ønsker å endre din epostadresse vennligst kontakt oss på{' '}
                <a href='mailto:reise@norsktur.no'>reise@norsktur.no</a> eller{' '}
                <a href='tel:+4738120320'>38120320</a>.
            </Text>
            <Text>
                E-postadressen er også brukernavnet ditt for pålogging til Min
                side. Vi vil sende viktig informasjon til denne adressen og
                derfor er det er viktig at den er korrekt og oppdatert til
                enhver tid.
            </Text>

            <FormLabel mt={8}>Ønsker du å motta nyhetsbrev?</FormLabel>

            <RadioGroup
                value={newsLetterState}
                onChange={(e) => handleNewsletterChange(e)}
            >
                <Stack>
                    <Radio
                        borderColor='NorskTur.Navy'
                        _checked={radioCheckedStyleProps}
                        value='false'
                    >
                        Nei
                    </Radio>
                    <Radio
                        borderColor='NorskTur.Navy'
                        _checked={radioCheckedStyleProps}
                        value='true'
                    >
                        Ja
                    </Radio>
                </Stack>
            </RadioGroup>

            <HStack
                spacing={6}
                display='block'
                textAlign='end'
                bottom={8}
                right={8}
            >
                <Button
                    variant='primary'
                    type='submit'
                    onClick={handleSubmit}
                    isLoading={loading}
                >
                    Lagre
                </Button>
            </HStack>
        </Box>
    );
}
