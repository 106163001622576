import React from 'react';
import { Heading, Link, Text, Image, Button, Box } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import loading from '../assets/loading.svg';

export type TripHeaderProps = {
    title: string;
    dateString: string;
    tripUrl: string;
    featuredImageUrl: string;
};

export default function TripHeader(props: TripHeaderProps) {
    const { title, dateString, tripUrl, featuredImageUrl } = props;
    return (
        <Box color='NorskTur.Navy'>
            <Heading size='lg'>{title}</Heading>
            <Text>{dateString}</Text>

            <Link href={tripUrl} target='_blank'>
                <Button
                    variant='Link'
                    textDecoration='underline'
                    padding={0}
                    size='sm'
                >
                    Lenke til turen
                </Button>
            </Link>

            <Box>
                <Image
                    src={featuredImageUrl}
                    fallbackSrc={loading}
                    alt='Turbilde'
                    objectFit='cover'
                    height='500px'
                    minW='100%'
                    rounded='lg'
                />
                <Box
                    position='relative'
                    right='20px'
                    top='-25px'
                    textAlign='right'
                >
                    <RouterLink to='/dagsprogram'>
                        <Button
                            size='lg'
                            padding='2rem'
                            variant='primary'
                            fontSize='xl'
                        >
                            Dagsprogram
                        </Button>
                    </RouterLink>
                </Box>
            </Box>
        </Box>
    );
}
