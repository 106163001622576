/* eslint-disable @typescript-eslint/no-shadow */
import { IWPPostData } from 'common/interfaces/wpPostData';
import { useQuery } from 'react-query';
import { wordpressClient, generateWPQueryUrl } from '../axiosClient';

export const useWpTrip = (tripNumber?: string) => {
    const { data, isFetching, isIdle, isError } = useQuery(
        [`get-trip/${tripNumber}`],
        async () => {
            const { data } = await wordpressClient.get<IWPPostData>(
                generateWPQueryUrl(tripNumber!)
            );
            return data;
        },
        { staleTime: Infinity, enabled: !!tripNumber }
    );
    return { data, isFetching, isIdle, isError };
};
