import React from 'react';
import { Box, Button, Image, Text } from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import { useDocuments } from 'api/hooks/ApiHooks';
import { useAuthContext } from 'common/context/authContext';
import TripCards from '../tripCard/tripCards';
import loading from '../assets/loading.svg';
import CardView from '../tripCard/card.view';
import { useWpTrip } from '../../api/hooks/WordpressHooks';
import TripDocuments from './tripDocuments';

type CardViewProps = {
    title: string;
    date: string | null | undefined;
    tripId: string;
    tripNumber: string;
    bookingReference: string;
    isActive: boolean;
    onClick: () => void;
};

export default function PlannedTripCard(props: CardViewProps) {
    const {
        title,
        date,
        tripId,
        tripNumber,
        bookingReference,
        isActive,
        onClick,
    } = props;

    const { data } = useWpTrip(tripNumber);

    const { data: documents, isFetching: isFetchingDocuments } = useDocuments(
        useAuthContext(),
        tripId
    );

    const scheduleAvailable =
        data?.program?.length && data.program[0].program_timetable;

    return (
        <>
            <Box
                position='relative'
                _hover={{
                    filter: 'brightness(120%)',
                    cursor: 'pointer',
                }}
                transition='brightness, .5s'
                onClick={onClick}
            >
                <Image
                    src={data && data.featured_image ? data.featured_image : ''}
                    fallbackSrc={loading}
                    objectFit='cover'
                    borderRadius='lg'
                    width={{ base: '100vw', md: '392px' }}
                    height='250px'
                />
                <Button
                    variant='primary'
                    position='absolute'
                    transform='translate(-50%, -50%)'
                    left='50%'
                    bottom='1px'
                    margin='0 auto'
                    maxW='70%'
                >
                    {`${title} ${date}`}
                </Button>
            </Box>
            {isActive && (
                <>
                    <CardView
                        path='/dagsprogram'
                        icon={<CalendarIcon />}
                        title='Dagsprogram'
                        primaryBtnText={
                            scheduleAvailable ? 'Se dagsprogram' : undefined
                        }
                        cardContent={
                            <Text>
                                {scheduleAvailable ? '' : 'Kommer snart'}
                            </Text>
                        }
                    />
                    <TripCards
                        tripId={tripId}
                        tripBookingReference={bookingReference}
                    />
                    <TripDocuments
                        documents={documents}
                        isLoading={isFetchingDocuments}
                    />
                </>
            )}
        </>
    );
}
