import React from 'react';
import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import moment from 'moment-timezone';
import 'moment/locale/nb';
import { IFlightLeg } from '../interfaces/flight';
import { formatSingleDate } from '../utilities/parser';
import { getTimeDiffDisplay } from '../utilities/timeFunctions';

type FlightLegProps = {
    flightLegs: IFlightLeg[];
};

export default function FlightLeg(props: FlightLegProps) {
    const { flightLegs } = props;

    moment.locale('nb');

    const maybeShowTransferInfo = (index: number) => {
        // No transfer if this is the last, or only leg
        if (flightLegs[index] !== flightLegs[flightLegs.length - 1]) {
            const currentLeg = flightLegs[index];
            const nextLeg = flightLegs[index + 1];

            return (
                <Text opacity={0.7} mt={3}>
                    -
                    <Text>
                        {getTimeDiffDisplay(
                            currentLeg?.arrivalTime || '',
                            nextLeg?.departureTime || ''
                        )}{' '}
                        transfer i {currentLeg?.destinationAirportName}
                    </Text>
                    -
                </Text>
            );
        }
        return <Box />;
    };

    const getWeekday = (weekDayIndex: number) => {
        const weekdays = ['ma', 'ti', 'on', 'to', 'fr', 'lø', 'sø'];
        return weekdays[weekDayIndex];
    };

    return (
        <UnorderedList ml={8} mt={2} listStyleType='none'>
            <ListItem>
                {`${getWeekday(
                    moment
                        .tz(
                            moment.utc(flightLegs[0].departureTime).toString(),
                            'Europe/Oslo'
                        )
                        .weekday()
                )} ${formatSingleDate(
                    flightLegs[0].departureTime,
                    false
                )} ${moment
                    .tz(
                        moment.utc(flightLegs[0].departureTime).toString(),
                        'Europe/Oslo'
                    )
                    .format('HH:mm')} - ${moment
                    .tz(
                        moment
                            .utc(flightLegs[flightLegs.length - 1].arrivalTime)
                            .toString(),
                        'Europe/Oslo'
                    )
                    .format('HH:mm')}`}
            </ListItem>
            {flightLegs.map((leg: IFlightLeg, index) => (
                <Box key={leg.flightNumber}>
                    <ListItem mt={4}>
                        {leg.airlineCode}
                        {leg.flightNumber}
                        <Text opacity={0.7} display='inline'>
                            {' '}
                            -{' '}
                            {getTimeDiffDisplay(
                                leg?.departureTime || '',
                                leg?.arrivalTime || ''
                            )}
                        </Text>
                    </ListItem>
                    <ListItem>
                        {moment
                            .tz(
                                moment.utc(leg.departureTime).toString(),
                                'Europe/Oslo'
                            )
                            .format('HH:mm')}{' '}
                        <Text display='inline' ml={3}>
                            {leg.originAirportName}
                        </Text>
                    </ListItem>
                    <ListItem>
                        {moment
                            .tz(
                                moment.utc(leg.arrivalTime).toString(),
                                'Europe/Oslo'
                            )
                            .format('HH:mm')}{' '}
                        <Text display='inline' ml={3}>
                            {leg.destinationAirportName}
                        </Text>
                    </ListItem>

                    {maybeShowTransferInfo(index)}
                </Box>
            ))}
        </UnorderedList>
    );
}
