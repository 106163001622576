import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    List,
    ListItem,
} from '@chakra-ui/react';
import { SortedRoomReportWithTotals } from 'common/interfaces/reports';
import React from 'react';

type Proptypes = {
    listData?: SortedRoomReportWithTotals;
};

function RemarksReportMobile(props: Proptypes) {
    const { listData } = props;

    const data = React.useMemo(() => {
        if (!listData) return [];

        const contacts = listData.rooms.map((room) => room?.contacts).flat();

        const sortedContacts = contacts.sort((a, b) => {
            const lastNameA = a && a.lastName ? a.lastName.toUpperCase() : '';
            const lastNameB = b && b.lastName ? b.lastName.toUpperCase() : '';
            if (lastNameA < lastNameB) {
                return -1;
            }
            if (lastNameA > lastNameB) {
                return 1;
            }
            return 0;
        });

        return sortedContacts.map((contact) => ({
            name: `${contact?.lastName || ''}, ${contact?.firstName || ''}`,
            mobilePhone: contact?.mobilePhone || '',
            remarks: contact?.remarksEng || '',
            allergyRemark: contact?.foodAllergyRemark || '',
        }));
    }, [listData]);

    return (
        <Accordion defaultIndex={[0]} allowMultiple>
            {data.map((row, index) => (
                <AccordionItem key={`${row.name}${index}`}>
                    <AccordionButton
                        _expanded={{
                            bg: 'NorskTur.Navy',
                            color: 'white',
                        }}
                    >
                        <Box as='span' flex='1' textAlign='left'>
                            {row.name}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel bg='NorskTur.GråLys'>
                        <List>
                            <ListItem>
                                <strong>Mobil:</strong> {row.mobilePhone}
                            </ListItem>
                            <ListItem>
                                <strong>Merknader:</strong> {row.remarks}
                            </ListItem>
                            <ListItem>
                                <strong>Allergi:</strong> {row.allergyRemark}
                            </ListItem>
                        </List>
                    </AccordionPanel>
                </AccordionItem>
            ))}
        </Accordion>
    );
}

export default RemarksReportMobile;
