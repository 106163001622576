import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Text,
} from '@chakra-ui/react';
import { SortedFlightReport } from 'common/interfaces/reports';
import React from 'react';

type Proptypes = {
    listData?: SortedFlightReport[];
};

function FlightReportMobile(props: Proptypes) {
    const { listData } = props;

    const data = React.useMemo(() => {
        return (listData ?? []).flatMap((report) => ({
            name: report.contacts.map((contact) => (
                <Text mb={2} key={`${contact.firstName}`}>
                    {contact.lastName}, {contact.firstName}
                </Text>
            )),
            flight: (
                <div key={report.flightDetails}>
                    <Text>{report.flightDetails}</Text>
                    <Text>Utreise: {report.outboundPnr}</Text>
                    <Text>Hjemreise: {report.inboundPnr}</Text>
                </div>
            ),
            total: report.noOfTravellers,
        }));
    }, [listData]);

    return (
        <Accordion defaultIndex={[0]} allowMultiple>
            {data.map((row) => (
                <AccordionItem key={row.flight.key}>
                    <AccordionButton
                        _expanded={{
                            bg: 'NorskTur.Navy',
                            color: 'white',
                        }}
                    >
                        <Box as='span' flex='1' textAlign='left'>
                            {row.flight}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel bg='NorskTur.GråLys'>
                        <Box mb={4} fontWeight='bold'>
                            Totalt: {row.total}
                        </Box>
                        <Text fontWeight='bold'>Påmeldte:</Text>
                        <Box>{row.name}</Box>
                    </AccordionPanel>
                </AccordionItem>
            ))}
        </Accordion>
    );
}

export default FlightReportMobile;
