import React from 'react';
import { Table, Tbody, Td, Tr } from '@chakra-ui/react';

type InvoiceDetailsProps = {
    amount: number;
    outstandingAmount: number;
    paidAmount: number;
};

export default function InvoiceDetails(props: InvoiceDetailsProps) {
    const { amount, outstandingAmount, paidAmount } = props;
    const formatter = new Intl.NumberFormat('nb-NO', {
        style: 'currency',
        currency: 'NOK',
    });
    return (
        <Table>
            <Tbody>
                <Tr>
                    <Td pl={0} pb={2}>
                        Beløp:
                    </Td>
                    <Td pl={0} pb={2} isNumeric>
                        {formatter.format(amount)}
                    </Td>
                </Tr>
                <Tr>
                    <Td pl={0} pb={2}>
                        Betalt:
                    </Td>
                    <Td pl={0} pb={2} isNumeric>
                        {formatter.format(paidAmount)}
                    </Td>
                </Tr>
                <Tr fontWeight='Bold'>
                    <Td pl={0} pb={2}>
                        Gjenstående å betale:
                    </Td>
                    <Td pl={0} pb={2} isNumeric>
                        {formatter.format(outstandingAmount)}
                    </Td>
                </Tr>
            </Tbody>
        </Table>
    );
}
