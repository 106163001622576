import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Text,
    List,
    ListItem,
} from '@chakra-ui/react';
import { SortedRoomReportWithTotals } from 'common/interfaces/reports';
import React from 'react';

type Proptypes = {
    listData?: SortedRoomReportWithTotals;
};

function RoomReportMobile(props: Proptypes) {
    const { listData } = props;

    const data = React.useMemo(
        () =>
            (listData?.rooms ?? []).flatMap((rooms) => {
                return {
                    name: (rooms?.contacts || []).map((contact, index) => (
                        <Text
                            mt={index + 1 > 1 ? 4 : 0}
                            key={`${contact.firstName}${contact.lastName}`}
                        >
                            {contact.lastName}, {contact.firstName}{' '}
                            {contact.travellerType === 'TourLeader'
                                ? '(RL)'
                                : ''}
                        </Text>
                    )),
                    companyName: (rooms?.contacts || []).map(
                        (contact) => contact.companyName
                    ),
                    room: rooms?.roomCategory ?? '',
                };
            }),
        [listData]
    );

    return (
        <Accordion defaultIndex={[0]} allowMultiple>
            {data.map((row, index) => (
                <AccordionItem key={`${row.room}${index}`}>
                    <AccordionButton
                        _expanded={{
                            bg: 'NorskTur.Navy',
                            color: 'white',
                        }}
                    >
                        <Box as='span' flex='1' textAlign='left'>
                            {row.name}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel bg='NorskTur.GråLys'>
                        <List>
                            <ListItem>{row.room}</ListItem>
                        </List>
                    </AccordionPanel>
                </AccordionItem>
            ))}
        </Accordion>
    );
}

export default RoomReportMobile;
