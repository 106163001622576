import React from 'react';
import { Icon } from '@chakra-ui/react';

export function AdditionsIcon(props: any) {
    return (
        <Icon viewBox='0 0 24 24' {...props}>
            <svg
                width='12'
                height='20'
                viewBox='0 0 12 20'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M7.79775 3.74945C8.79133 3.74945 9.59744 2.90973 9.59744 1.87473C9.59744 0.839721 8.79133 0 7.79775 0C6.80416 0 5.99805 0.839721 5.99805 1.87473C5.99805 2.90973 6.80416 3.74945 7.79775 3.74945ZM11.3409 9.57282L10.4673 9.11195L10.1036 7.96368C9.55245 6.22174 8.01521 5.00317 6.27175 4.99927C4.92198 4.99536 4.17586 5.39374 2.77359 5.9835C1.96373 6.32329 1.30009 6.96773 0.910159 7.78792L0.658951 8.31909C0.366501 8.93619 0.602711 9.68608 1.19136 9.99463C1.77626 10.3032 2.48864 10.0532 2.78484 9.43612L3.03605 8.90495C3.16728 8.63155 3.38474 8.41674 3.6547 8.30347L4.65953 7.88166L4.08962 10.2524C3.89465 11.0648 4.10462 11.9279 4.64828 12.5489L6.89415 15.1033C7.1641 15.4118 7.35532 15.7828 7.4528 16.1851L8.13894 19.048C8.30016 19.7159 8.95255 20.126 9.59369 19.958C10.2348 19.7901 10.6285 19.1105 10.4673 18.4426L9.63494 14.9666C9.53745 14.5643 9.34623 14.1893 9.07628 13.8847L7.37032 11.9436L8.01521 9.26036L8.22142 9.9048C8.42014 10.5336 8.84757 11.0531 9.40997 11.3499L10.2836 11.8108C10.8685 12.1193 11.5809 11.8694 11.8771 11.2523C12.1658 10.6391 11.9295 9.88136 11.3409 9.57282ZM2.7586 15.0681C2.63862 15.3845 2.45865 15.6696 2.22619 15.9078L0.351503 17.8646C-0.117168 18.3528 -0.117168 19.1456 0.351503 19.6338C0.820174 20.1221 1.57755 20.1221 2.04622 19.6338L4.27334 17.3139C4.50205 17.0756 4.68202 16.7905 4.80575 16.4741L5.31192 15.154C3.23852 12.7989 3.86091 13.5215 3.53472 13.0567L2.7586 15.0681Z'
                    fill='currentColor'
                />
            </svg>
        </Icon>
    );
}
