import React from 'react';
import { Grid } from '@chakra-ui/react';
import { useUpdateAdditions } from 'api/hooks/ApiHooks';
import { useAuthContext } from '../context/authContext';
import {
    ITripOptionalAddition,
    IBookedOptionalAddition,
    IUpdateOptionalAdditions,
} from '../interfaces/addition';
import AdditionCard from './additionCards';

type ContactBookedAdditionsProps = {
    availableAdditions: ITripOptionalAddition[];
    bookingReference: string;
    contactBookedAdditions?: IBookedOptionalAddition;
    contactId?: string;
};

export default function ContactBookedAdditions(
    props: ContactBookedAdditionsProps
) {
    const {
        contactBookedAdditions,
        availableAdditions,
        bookingReference,
        contactId: singleContactId,
    } = props;

    const auth = useAuthContext();
    const [bookedAdditions, setBookedAdditions] = React.useState(
        contactBookedAdditions?.tripOptionalAdditions || []
    );

    const { mutate } = useUpdateAdditions(auth);

    function IDinContactBookedAdditions(
        currentAdditionId: number,
        additions?: ITripOptionalAddition[]
    ) {
        let found = false;
        found =
            !!additions &&
            additions.some((addition: ITripOptionalAddition) => {
                return addition.optionalAdditionId === currentAdditionId;
            });
        return found;
    }

    function updateBookedAdditions(
        addition: ITripOptionalAddition
    ): ITripOptionalAddition[] {
        const updatedAdditions: ITripOptionalAddition[] = [];
        if (bookedAdditions) {
            bookedAdditions?.forEach((singleAddition: ITripOptionalAddition) =>
                updatedAdditions.push(singleAddition)
            );
        }

        // Check if addition already booked
        if (
            IDinContactBookedAdditions(
                addition.optionalAdditionId,
                bookedAdditions
            )
        ) {
            const removeIndex = updatedAdditions
                .map((x) => x.optionalAdditionId)
                .indexOf(addition.optionalAdditionId);
            if (removeIndex >= 0) {
                updatedAdditions.splice(removeIndex, 1);
            }
        } else {
            updatedAdditions.push(addition);
        }
        setBookedAdditions(updatedAdditions);
        return updatedAdditions;
    }
    const onSucces = (
        addition: ITripOptionalAddition,
        onSettled: () => void,
        onError: () => void
    ) => {
        const updatedAdditionIds: number[] = [];
        updateBookedAdditions(addition).forEach(
            (singleAddition: ITripOptionalAddition) => {
                updatedAdditionIds.push(singleAddition.optionalAdditionId);
            }
        );

        const additions: IUpdateOptionalAdditions = {
            contactId:
                (contactBookedAdditions &&
                    contactBookedAdditions.contact.contactId) ||
                singleContactId ||
                '',
            bookingReference,
            optionalAdditionIds: updatedAdditionIds,
        };
        mutate(additions, { onSettled, onError });
    };

    const onSubmit = (
        addition: ITripOptionalAddition,
        onSettled: () => void,
        onError: () => void
    ) => {
        IDinContactBookedAdditions(
            addition.optionalAdditionId,
            bookedAdditions
        );
        onSucces(addition, onSettled, onError);
    };

    return (
        <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
            {availableAdditions.map((addition: ITripOptionalAddition) => (
                <AdditionCard
                    key={addition.optionalAdditionId}
                    booked={IDinContactBookedAdditions(
                        addition.optionalAdditionId,
                        bookedAdditions
                    )}
                    addition={addition}
                    onToggleAddition={onSubmit}
                />
            ))}
        </Grid>
    );
}
