import {
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Text,
    Box,
    Button,
} from '@chakra-ui/react';
import { SortedFlightReport } from 'common/interfaces/reports';
import React from 'react';
import { usePagination, useTable } from 'react-table';
import { useReportDownload } from 'api/hooks/ApiHooks';
import { useAuthContext } from 'common/context/authContext';
import Paginator from './paginator';

type Proptypes = {
    tripId: string;
    listData?: SortedFlightReport[];
};

function FlightReportTable(props: Proptypes) {
    const { listData, tripId } = props;

    const data = React.useMemo(() => {
        return (listData ?? []).flatMap((report) => ({
            name: report.contacts.map((contact) => (
                <Text mb={2} key={`${contact.firstName}`}>
                    {contact.lastName}, {contact.firstName}
                </Text>
            )),
            flight: (
                <Box key={report.flightDetails} width={400}>
                    <Text fontWeight='bold' mb={4}>
                        {report.flightDetails}
                    </Text>
                    <Text>Utreise:</Text>
                    <Text>{report.outboundPnr}</Text>
                    <Text mt={4}>Hjemreise:</Text>
                    <Text>{report.inboundPnr}</Text>
                </Box>
            ),
            total: report.noOfTravellers,
        }));
    }, [listData]);

    const { startDownload, loading } = useReportDownload(
        useAuthContext(),
        tripId,
        'Deltakerliste ink. fly-all'
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'Påmeldte',
                accessor: 'name', // accessor is the "key" in the data
                minWidth: 200,
            } as const,
            {
                Header: 'Fly',
                accessor: 'flight',
            } as const,
            {
                Header: 'Totalt',
                accessor: 'total',
                maxWidth: 100,
            } as const,
        ],
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination
    );
    return (
        <div>
            <Table variant='striped' {...getTableProps()} mb={4}>
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <Td
                                            {...cell.getCellProps({
                                                style: {
                                                    width: cell.column.width,
                                                    maxWidth:
                                                        cell.column.maxWidth,
                                                    minWidth:
                                                        cell.column.minWidth,
                                                },
                                            })}
                                        >
                                            {cell.render('Cell')}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            <Paginator
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                currentPageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
                numberOfPages={pageCount}
                previousPage={previousPage}
                pageOptions={pageOptions}
                pageSize={pageSize}
                setPageSize={setPageSize}
            />
            <Button
                float='right'
                mx={5}
                onClick={startDownload}
                isLoading={loading}
            >
                Skriv ut
            </Button>
        </div>
    );
}

export default FlightReportTable;
