import React from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ErrorBoundary from 'api/ErrorBoundary';
import { NavBarContainer } from '../nav/navBar.view';
import Footer from './footer.view';
import ScrollToTop from '../containers/scrollToTop';
import isAuthenticated from '../auth/isAuthenticated';
import { useAuthContext } from '../context/authContext';

export const Root: React.FC = () => {
    const { user } = useAuthContext();
    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!isAuthenticated(user?.access_token, user?.expired)) {
            navigate('/login', {
                replace: true,
                state: {
                    from: {
                        pathname: location.pathname,
                        search: location.search,
                    },
                },
            });
        }
    }, [
        location.pathname,
        location.search,
        navigate,
        user?.access_token,
        user?.expired,
    ]);

    return (
        <Box
            color='NorskTur.Navy'
            minHeight='100vh'
            display='flex'
            flexDirection='column'
        >
            <ScrollToTop />
            <NavBarContainer />
            <Box mb='150px'>
                <ErrorBoundary>
                    <Outlet />
                </ErrorBoundary>
            </Box>
            <Footer />
        </Box>
    );
};

export default Root;
