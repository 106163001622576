import React from 'react';
import { Icon } from '@chakra-ui/react';

type PaymenIconProps = {
    notification: boolean;
    [x: string]: any;
};

export function PaymentIcon(props: PaymenIconProps) {
    const { notification, ...rest } = props;
    if (notification) {
        return (
            <Icon viewBox='0 0 24 24' {...rest}>
                <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='currentColor'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        d='M18 8H2C0.89 8 0.00999999 8.89 0.00999999 10L0 22C0 23.11 0.89 24 2 24H18C19.11 24 20 23.11 20 22V10C20 8.89 19.11 8 18 8ZM18 22H2V16H18V22ZM18 12H2V10H18V12Z'
                        fill='currentColor'
                    />
                    <path
                        d='M15.5833 16.6667C20.1857 16.6667 23.9167 12.9357 23.9167 8.33333C23.9167 3.73096 20.1857 0 15.5833 0C10.981 0 7.25 3.73096 7.25 8.33333C7.25 12.9357 10.981 16.6667 15.5833 16.6667Z'
                        fill='#AF0303'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M15.5833 4C16.1356 4 16.5833 4.44772 16.5833 5V8.33333C16.5833 8.88562 16.1356 9.33333 15.5833 9.33333C15.031 9.33333 14.5833 8.88562 14.5833 8.33333V5C14.5833 4.44772 15.031 4 15.5833 4Z'
                        fill='white'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M14.5833 11.6667C14.5833 11.1145 15.031 10.6667 15.5833 10.6667H15.5921C16.1444 10.6667 16.5921 11.1145 16.5921 11.6667C16.5921 12.219 16.1444 12.6667 15.5921 12.6667H15.5833C15.031 12.6667 14.5833 12.219 14.5833 11.6667Z'
                        fill='white'
                    />
                </svg>
            </Icon>
        );
    }
    return (
        <Icon viewBox='0 0 24 24' {...rest} mt={2}>
            <svg
                width='20'
                height='16'
                viewBox='0 0 20 16'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V8H18V14ZM18 4H2V2H18V4Z'
                    fill='currentColor'
                />
            </svg>
        </Icon>
    );
}
