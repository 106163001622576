import React from 'react';
import { Box } from '@chakra-ui/react';

type Proptypes = {
    breakpoint: 'sm' | 'md' | 'lg' | 'xl';
    baseElement: JSX.Element;
    mobileElement: JSX.Element;
};

function MobileSplitDisplay(props: Proptypes) {
    const { breakpoint, baseElement, mobileElement } = props;

    return (
        <div>
            <Box
                display={{
                    base: 'none',
                    [breakpoint]: 'block',
                }}
            >
                {baseElement}
            </Box>
            <Box
                display={{
                    base: 'block',
                    [breakpoint]: 'none',
                }}
            >
                {mobileElement}
            </Box>
        </div>
    );
}

export default MobileSplitDisplay;
