/* eslint-disable camelcase */
export default function isAuthenticated(
    access_token?: string,
    expired?: boolean
): boolean {
    if (access_token && !expired) {
        return true;
    }
    return false;
}
