import React from 'react';
import { Text, Avatar, Heading, Stack } from '@chakra-ui/react';
import avatar from '../assets/profile-icon.svg';

type ProfileHeaderProps = {
    email: string;
};

export default function ProfileHeader(props: ProfileHeaderProps) {
    const { email } = props;
    return (
        <div>
            <Stack direction='row' mt={8} mb={4}>
                <Avatar mr={2} size='xs' src={avatar} />
                <Heading size='md'>Om deg</Heading>
            </Stack>
            <Text>Du er nå logget inn som {email}</Text>
        </div>
    );
}
