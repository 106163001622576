import React from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Link,
    Text,
} from '@chakra-ui/react';
import moment from 'moment';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { IInvoice } from 'common/interfaces/invoice';
import { useAuthContext } from '../context/authContext';
import { ReturnLink } from '../nav/returnLink';
import { StandardSpinner } from '../spinner/standarsSpinner';
import { formatSingleDate } from '../utilities/parser';
import InvoiceDetails from './invoiceDetails.view';
import InvoiceSummary from './invoiceSuymmmary.view';
import {
    useContact,
    useInvoice,
    useTrip,
    useTripById,
} from '../../api/hooks/ApiHooks';

export default function InvoiceContainer() {
    const auth = useAuthContext();
    const [searchParams] = useSearchParams();
    const tripNumber = searchParams.get('tripNumber');

    const { data: trip } = useTrip(auth, tripNumber);

    const {
        data: crmTrip,
        isFetching: isNapexDataFetching,
        isIdle: isNapexDataIdle,
    } = useTripById(auth, trip?.tripId);

    const { data: contactData, isFetching: isContactFetching } =
        useContact(auth);

    const {
        data: invoiceData,
        isFetching: isInvoiceFetching,
        isIdle: isInvoiceIdle,
    } = useInvoice(auth, trip?.tripId);

    const isPaid = (invoice: IInvoice): boolean => {
        if (invoice.outstandingAmount === 0) return true;
        return false;
    };

    const isLoading =
        isInvoiceFetching ||
        isInvoiceIdle ||
        isContactFetching ||
        isNapexDataFetching ||
        isNapexDataIdle;

    return (
        <Container maxW='container.md'>
            {isLoading ? (
                <StandardSpinner title='Henter fakturainformasjon' />
            ) : (
                <div>
                    <ReturnLink to='/' />
                    {!invoiceData ? (
                        <Container maxW='container.md' height='30vh'>
                            <Heading size='lg' mt={2}>
                                Fant ingen faktura.
                            </Heading>
                        </Container>
                    ) : (
                        <Container maxW='container.md'>
                            <Heading size='lg' mt={2}>
                                Dine fakturaer
                            </Heading>
                            <Text>{crmTrip?.tripName}</Text>
                            <Text mb={8}>
                                {crmTrip &&
                                    formatSingleDate(
                                        crmTrip.departureDate,
                                        false
                                    )}
                            </Text>

                            <Text fontWeight='bold'>
                                Reisende:{' '}
                                {`${contactData?.firstName} ${contactData?.lastName}`}
                            </Text>
                            <Text fontWeight='bold' mb={4}>
                                Kundenr: {contactData?.accountingNumber}
                            </Text>
                            <Text
                                fontWeight='bold'
                                mb={4}
                                color='NorskTur.Varselrød'
                            >
                                Det kan ta opptil 5 dager fra innbetaling til
                                det gjenspeiles her
                            </Text>
                            <Accordion allowMultiple>
                                {invoiceData &&
                                    invoiceData.length > 0 &&
                                    invoiceData.map((invoice) => (
                                        <AccordionItem key={invoice.invoiceId}>
                                            <h2>
                                                <AccordionButton>
                                                    <Box
                                                        as='span'
                                                        flex='1'
                                                        textAlign='left'
                                                    >
                                                        Faktura:{' '}
                                                        {invoice.invoiceNumber}
                                                        {!isPaid(invoice) &&
                                                            ' - Ikke betalt'}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Text fontWeight='bold'>
                                                    Fakturanumer:{' '}
                                                    {invoice.invoiceNumber}
                                                </Text>
                                                <InvoiceDetails
                                                    amount={invoice.amount}
                                                    outstandingAmount={
                                                        invoice.outstandingAmount
                                                    }
                                                    paidAmount={
                                                        invoice.paidAmount
                                                    }
                                                />
                                                <InvoiceSummary
                                                    invoiceDate={moment(
                                                        invoice.invoiceDate
                                                    ).format('DD.MM.YYYY')}
                                                    invoiceDueDate={moment(
                                                        invoice.dueDate
                                                    ).format('DD.MM.YYYY')}
                                                    cid={invoice.kidNumber}
                                                />
                                                <Flex
                                                    mt={12}
                                                    alignItems='center'
                                                    justifyContent={{
                                                        base: 'space-between',
                                                        md: 'flex-end',
                                                    }}
                                                >
                                                    <Link
                                                        as={RouterLink}
                                                        to={`/betaling?invoiceId=${
                                                            invoice.invoiceId
                                                        }&tripNumber=${
                                                            tripNumber ||
                                                            crmTrip?.tripNumber
                                                        }`}
                                                    >
                                                        <Button variant='primary'>
                                                            Betal med kort
                                                        </Button>
                                                    </Link>
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    ))}
                            </Accordion>
                        </Container>
                    )}
                </div>
            )}
        </Container>
    );
}
